import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConfirmDialogConfig, ILabel } from '../../../interfaces';
import { MeasurementService } from '../../../services/measurement.service';

@Component({
  selector: 'app-custom-measurement-type-modal',
  templateUrl: './custom-measurement-type-modal.component.html',
  styleUrl: './custom-measurement-type-modal.component.scss',
})
export class CustomMeasurementTypeModalComponent {
  diplayModal: boolean = false;
  @Input() propertyModalConfig: IConfirmDialogConfig;
  @Output() confirmAction: EventEmitter<any> = new EventEmitter();
  measurementTypes: ILabel[] = [];
  selectedMeasurementType: ILabel | null;
  constructor(private measurementService: MeasurementService) {
    this.getMeasturementTypes();
  }
  async getMeasturementTypes() {
    const response = await this.measurementService.getLabels('Measurement');
    this.measurementTypes.push(...response.records);
  }

  openModal() {
    this.diplayModal = true;
  }

  closeModal() {
    this.diplayModal = false;
    this.selectedMeasurementType = null;
  }

  onConfirmClick() {
    this.confirmAction.emit({
      status: true,
      data: this.selectedMeasurementType,
    });
  }
  onCancelClick() {
    this.confirmAction.emit({ status: false });
  }

  onSelectType(type: ILabel) {
    this.selectedMeasurementType =
      type !== this.selectedMeasurementType ? type : null;
  }
}
