export interface IUserInfo {
  accountLinks: string[];
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  member: any[];
  profilePicture: string;
  pilotInformation: any[];
  socialLinks: any[];
  updatedAt: string;
  userCertificate: any[];
  username: string;
  verified: Boolean;
  _id: string;
}
export interface ISiteListResponse {
  meta?: {
    nextCursor?: string;
    prevCursor?: string;
    [key: string]: any;
  };
  records: ISiteRecord[];
}
export interface ISiteRecord {
  _id: string;
  attachedAsset: any[];
  createdAt: string;
  externalClientSiteId: string;
  id: string;
  industryType: string;
  isMigratedData: Boolean;
  metadata: any;
  recordType: string;
  siteLatitude: string;
  siteLongitude: string;
  siteName: string;
  siteType: string;
  updatedAt: string;
  workspaceId: string;
  __v?: number;
}

export interface ILabel {
  _id: string;
  name: string;
  workspaceId: string;
  labelType: string;
  metadata: Record<string, any>;
  deletedAt: string | null;
  deletedBy: string | null;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  description?: string;
}

export interface ILabelResponse {
  meta?: {
    nextCursor?: string;
    prevCursor?: string;
  };
  records: ILabel[];
}
