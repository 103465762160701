import { Stage } from 'konva/lib/Stage';
import { IDataResourceExif, IDataResourceRecord } from './annotation-api-response.interface';
import { Layer } from 'konva/lib/Layer';
import { Line } from 'konva/lib/shapes/Line';
import { Rect } from 'konva/lib/shapes/Rect';
import { IFindings } from './findings-listing.interface';
import { IExifData } from './common.interface';
import { Group } from 'konva/lib/Group';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
export interface ICanvasStageProperties {
  id: string;
  width: number;
  height: number;
}
export interface IThumbnailInstance {
  stage: Stage,
  layer: Layer,
  bgGroup?: any,
}
export interface ICanvasCooridnates {
  title?: string,
  type?: string,
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface ICanvasDataSetItems {
  id: string;
  name: string;
  url: string;
  coorinate: Array<ICanvasCooridnates>;
  stageProperty: ICanvasStageProperties;
  isLoaded: boolean;
}
export interface IResourceImageRecord {
  isSnapshotImage?: boolean;
  updatedAt: any;
  gpsLongitude: string;
  gpsLatitude: string;
  _id: string,
  name: string,
  dataResourceId: string,
  resourceTag: string,
  s3Key?: string,
  url: string,
  indexKey?: number,
  parentResourceId: string,
  record?: IDataResourceRecord,
  fileName?: string,
  imageName: string,
  thumbnailTags?: string[],
  displayTag?: string,
  actualTag?: string,
  showTag?: boolean,
  isAnnotated?: boolean,
  isReviewed?: boolean,
  src?: string,
  metadata?: {
    exif: IDataResourceExif | IExifData,
    isReviewed?: boolean
  }
  tags?: string[],
  assetFindings?: IFindings,
  missionId?: string,
  workspaceId?: string,
  gimbalYawDegreeDirection?: string,
  absoluteAltitudeMeters?: number,
  shortestKey?: number,
  dateTimeOriginal?: string,
}
export interface IStageResourceRecord {
  _id: any,
  thumbUrl: any,
  dataResourceId: string,
  type: string,
  originUrl: string,
  compressedUrl: string,
  originFulId: string,
  stageProperty: any,
  isLoaded: boolean,
  verify_checked?: boolean,
  verify_removed?: boolean,
  validate: boolean,
  annotationRecords: any,
  isThumbnailMode: boolean,
  gpsLatitude: string,
  gpsLongitude: string,
  updatedAt: string,
  fileName: any,
  showAll: boolean,
  imageName: string,
  isSnapshotImage?: boolean,
  isCompressed: boolean,
  isVerticed?: boolean,
  diskCache?: boolean,
  thumbnailTags?: string[],
  displayTag?: string,
  actualTag?: string,
  showTag?: boolean,
  isAnnotated?: boolean,
  isReviewed?: boolean,
  originRecord?: IDataResourceRecord
  src?: string,
  gimbalYawDegreeDirection?: string,
  tags?: string[],
  dateTimeOriginal?: string,
}
export interface ICanvasRectangleBox {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  name?: string,
  id?: string,
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  shadowBlur?: number
  draggable?: boolean,
  opacity?: number,
  points?: number[],
}
export interface ICanvasQueryParams {
  assetId: string;
  siteId: string;
  projectId: string;
  missionId: string;
  resourceType: string;
  workspaceId?: string;
}
export interface IPointerCoordinates {
  x?: number,
  y?: number
}
export interface ISaveAnnotationItems {
  type: string,
  dataResourceId: string,
  geometry: IGeometricPolygon,
  confidence: null,
  classLabels?: string[],
  collectionId?: string,
  missionId?: string,
  tags?: string[],
  metadata?: {},
}
export interface IGeometricPolygon {
  type: string,
  coordinates: Array<[number, number][]>
}
export interface ICanvasImageProperty {
  image: any; //value from library
  width: number;
  height: number;
  opacity?: number;
  name?: string;
  blurRadius?: number;
}
export interface IAnnotationImageResp {
  originImages: IDataResourceRecord[],
  thumbnailImages: IDataResourceRecord[],
  compressedImages: IDataResourceRecord[],
}
export interface IDataResourceParams {
  projectId: string;
  missionId: string;
  resourceType: string;
  tags: string;
  pageLimit: number;
  isPreSignedUrl: boolean;
}
export enum Annotation2DControl {
  POLYLINE = 'polyline',
  RECT = 'rect',
  POINT = 'point',
  NO_ACTIVE_CONTROL = '',
}
export enum Annotation2DControlAction {
  RESIZE = 'resize',
  ROTATE = 'rotate',
  VERTICE = 'vertice',
  DELETE = 'delete',
  RESET = 'reset',
  NO_ACTIVE_CONTROL = '',
}
export interface IPolygonVerticePoints {
  x: number,
  y: number
}
export interface IUniqueIds {
  [key: string]: boolean;
}
export interface IConfirmDialogConfig {
  title?: string,
  message?: string,
  confirmBtnText?: string,
  cancelBtnText?: string
}
export interface IImageViewerDefaultTagObject {
  displayName: string,
  actualTag: string,
  checked?: boolean
}
export interface ITagResourceGroup {
  [key: string]: IStageResourceRecord[];
}
export interface IRectangleProperty {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface IAiObjectProps {
  drawBox: Rect | Line,
  backgroundImgGroup: any,
  isAnnotatedTagByAi: boolean
}
export interface IImageSourceTag {
  imageThermal: {
    thumbnailImages: IResourceImageRecord[],
    originImages: IResourceImageRecord[],
    compressedImages: IResourceImageRecord[],
    rawThermalImages: IResourceImageRecord[],
  },
  imageRGB: {
    thumbnailImages: IResourceImageRecord[],
    originImages: IResourceImageRecord[],
    compressedImages: IResourceImageRecord[],
    rawThermalImages: IResourceImageRecord[],
    overAllThumbnailImages?: IResourceImageRecord[],
  }
}
export interface IImageCoordinates {
  gpsLatitude: string,
  gpsLongitude: string,
  gimbalYawDegreeDirection: string,
  absoluteAltitudeMeters: number
}
export interface IVideoSourceTag {
  videoRGB: {
    origFullResVideo: IResourceVideoRecord[],
    videoThumbnailImages: IResourceImageRecord[],
  }
  [key: string]: any;
}
export interface IResourceVideoRecord {
  updatedAt: any;
  _id: string,
  name: string,
  resourceTag: string,
  s3Key?: string,
  url: string,
  indexKey?: number,
  parentResourceId?: string,
  record?: IDataResourceRecord,
  fileName?: string,
  imageName: string,
  thumbnailTags?: string[],
  displayTag?: string,
  actualTag?: string,
  showTag?: boolean,
  src?: string,
  tags?: string[],
  missionId?: string,
  workspaceId?: string,
  thumbnailImage?:string
}
export interface IPrimaryAllTowerAssets {
  [towerId: string]: IStageResourceRecord[];
}
export interface ICurrentSelectedAnnotation {
  annotationId: string,
  selectedItem: IStageResourceRecord,
  drawBox: Line,
  findingId: string,
  projectId: string,
  missionId: string,
  siteId: string,
  workspaceId: string
}
export interface IImageTypes {
  imageType: IImageSourceTag;
}
export interface IThumbnailRenderInstance {
  targetStage: Stage;
  layer: Layer;
  bgGroup: Group | Shape<ShapeConfig> | null;
}
export interface IEventEmitAnnotation {
  item : IStageResourceRecord,
  method: string,
  payload: ISaveAnnotationItems,
  keyId: string,
  itemType: string,
}
export interface IAnnotationHistory{
  [boxId:string]:number[]
}