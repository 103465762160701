import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { LoaderState } from 'projects/annotation-2d/src/app/interfaces/loader.interface';
import {
  IDataResourceExif,
  IDeltaCalcProperty,
  IExifData,
  ILabelResponse,
  IMeasurementData,
  IMeasurementDataResponnse,
  IMeasurementDetail,
  IThermalParameters,
  MarkerState,
} from '../interfaces';
import { calculateMinMaxInList } from '../utils/calculate-min-max';
import { ThermalService } from './thermal.service';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Map } from 'mapbox-gl';
import { HttpClient } from '@angular/common/http';
import { Routes } from './routes';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  private markerSubject = new Subject<MarkerState>();
  measurementSubject: Subject<string> = new Subject<string>();
  measurementPassDataSubject: Subject<any> = new Subject<any>();
  workspaceId: string;
  styles = [
    {
      id: 'gl-draw-line',
      type: 'line',
      paint: {
        'line-color': '#aaaaaa',
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-marker',
      type: 'circle',
      filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
      paint: {
        'circle-color': '#016064',
        'circle-radius': 8,
      },
    },
    {
      id: 'gl-draw-marker-inner',
      type: 'circle',
      filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
      paint: {
        'circle-color': '#fefefe',
        'circle-radius': 4,
      },
    },
  ];

  constructor(
    private thermalService: ThermalService,
    private httpClient: HttpClient
  ) {
    const userData = localStorage.getItem('user-data');
    if (userData) {
      const parsedData = JSON.parse(userData);
      this.workspaceId = parsedData?.activeWorkspaceId || null;
    }
  }

  async postMeasurementData(data: IMeasurementData): Promise<IMeasurementData> {
    const response = await this.httpClient
      .post<IMeasurementData>(Routes.POST_MEASUREMENT, data)
      .toPromise();
    if (!response) {
      throw new Error('Received undefined response.');
    }
    return response;
  }

  async getLabels(query: string | null = null): Promise<ILabelResponse> {
    const queryParams = query ? `labelType=${query}` : '';
    const response = await this.httpClient
      .get<ILabelResponse>(`${Routes.LABEL_LOOKUP}?${queryParams}`)
      .toPromise();

    if (response === undefined) {
      throw new Error('Received undefined response.');
    }

    return response;
  }

  async getMeasurementData(params: {
    missionId: string;
    pageLimit?: number;
  }): Promise<IMeasurementDataResponnse> {
    let queryParams = '';
    Object.keys(params).forEach((paramKey) => {
      if (!params[paramKey as keyof typeof params]) return;
      queryParams += `${paramKey}=${params[paramKey as keyof typeof params]}&`;
    });
    const response = await this.httpClient
      .get<IMeasurementDataResponnse>(
        `${Routes.POST_MEASUREMENT}?${queryParams}`
      )
      .toPromise();

    if (!response) {
      throw new Error('Measurement data response is undefined or null.');
    }

    return response;
  }

  async deleteMeasurementData(id: string): Promise<null> {
    const response = await this.httpClient
      .delete<null>(`${Routes.POST_MEASUREMENT}/${id}`)
      .toPromise();

    if (response === null) {
      return response;
    } else {
      throw new Error('Received unexpected response.');
    }
  }

  updateMeasurementById(measurementId: string, data: object): Observable<any> {
    return this.httpClient
      .patch<any>(`${Routes.POST_MEASUREMENT}/${measurementId}`, data)
      .pipe(take(1));
  }
  getDeltaCalculation(
    measurementDataList: IMeasurementDetail[]
  ): IDeltaCalcProperty {
    const deltaCalcProperty: IDeltaCalcProperty = {
      shownDelta: measurementDataList.length >= 2 || false,
      deltaValue: 0,
      temperatureType: '',
      highSpotMeasureName: '',
      lowerestSpotMeasureName: '',
    };

    if (deltaCalcProperty.shownDelta) {
      const { maxVal, minVal } = calculateMinMaxInList(
        measurementDataList,
        'measurementValue'
      );

      deltaCalcProperty.temperatureType =
        measurementDataList[0]?.measurementType ?? '';

      deltaCalcProperty.deltaValue = Number(
        Math.abs(maxVal - minVal).toFixed(1)
      );

      deltaCalcProperty.highSpotMeasureName =
        measurementDataList.find(
          (elm) => parseFloat(elm.measurementValue) === maxVal
        )?.measurementName ?? '';

      deltaCalcProperty.lowerestSpotMeasureName =
        measurementDataList.find(
          (elm) => parseFloat(elm.measurementValue) === minVal
        )?.measurementName ?? '';
    }

    return deltaCalcProperty;
  }

  updateParameters(thermalExIfParamsLatestVal: any) {
    const thermalBodyExif = thermalExIfParamsLatestVal.metadata?.exif;

    if (thermalBodyExif) {
      this.thermalService.activeThermalParametersSubject.next({
        ...thermalExIfParamsLatestVal,
      });
    }
  }

  set(state: MarkerState) {
    this.markerSubject.next(state);
  }

  get() {
    return this.markerSubject.asObservable();
  }
  drawLine(): MapboxDraw {
    return new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        line_string: false,
        trash: false,
      },
      defaultMode: 'draw_line_string',
      styles: this.styles,
    });
  }
  clearDraw(mapBoxGl: Map, drawLine: MapboxDraw) {
    const hasDrawControl = mapBoxGl.hasControl(drawLine);
    if (hasDrawControl) {
      mapBoxGl.removeControl(drawLine);
    }
    if (mapBoxGl.getSource('mapbox-gl-draw-hot')) {
      mapBoxGl.removeSource('mapbox-gl-draw-hot');
    }
    if (mapBoxGl.getSource('mapbox-gl-draw-cold')) {
      mapBoxGl.removeSource('mapbox-gl-draw-cold');
    }
  }

  createLabelObj(
    geoJSONMultiPoint: any,
    length: number,
    missionId: string,
    dataResourceId: string,
    id: string,
    name: string
  ) {
    return {
      geometry: geoJSONMultiPoint,
      calculatedValue: length,
      missionId: missionId,
      dataResourceId: dataResourceId,
      workspaceId: this.workspaceId,
      measurementType: '2d',
      measurementLabel: id,
      name: name,
    };
  }
  sourceDisplayLine(): MapboxDraw {
    return new MapboxDraw({
      displayControlsDefault: false,
      styles: this.styles,
    });
  }

  polygonDraw(): MapboxDraw {
    return new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: false,
      },
      defaultMode: 'draw_polygon',
      styles: this.styles,
    });
  }

  removeAllListeners(mapBoxGl: Map) {
    const eventTypes = ['draw.create', 'draw.delete', 'draw.update'];
    eventTypes.forEach((eventType) => {
      const listeners = (mapBoxGl as any)._listeners[eventType];
      if (listeners) {
        listeners.slice().forEach((listener: (ev: any) => void) => {
          mapBoxGl.off(eventType, listener);
        });
      }
    });
  }
}
