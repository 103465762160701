import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CANVAS_COMPONENT,
  CREATE_DEFECT_PROPERTIES,
  USER_ROLE_PERMISSIONS,
} from 'projects/digital-twin/src/app/constants';
import {
  ISceneObject,
  ISceneObjectProperties,
} from 'projects/digital-twin/src/app/interfaces';
import { IUserRolesAndPermissions } from 'projects/digital-twin/src/app/interfaces/permission.interface';
import {
  CanvasDataService,
  PermissionService,
} from 'projects/digital-twin/src/app/services';
import { CustomPropertyModalComponent } from 'projects/digital-twin/src/app/shared/components/custom-property-modal/custom-property-modal.component';

@Component({
  selector: 'app-properties-form',
  templateUrl: './properties-form.component.html',
  styleUrls: ['./properties-form.component.scss'],
})
export class PropertiesFormComponent {
  @Input() properties: ISceneObjectProperties;
  @Input() componentId: string;
  @ViewChild('propertyModal')
  private propertyModal: CustomPropertyModalComponent;
  canvasComponentConst = CANVAS_COMPONENT;
  createDefectPropertiesConst = CREATE_DEFECT_PROPERTIES;
  userRolePermissions: IUserRolesAndPermissions = USER_ROLE_PERMISSIONS;
  selectedPropertyKey: string = '';
  propertyFormGroup: FormGroup;
  selectedId:string
  isPropertyUpdatedPermission = false;

  constructor(
    private formBuilder: FormBuilder,
    private canvasDataService: CanvasDataService,
    private permissionService: PermissionService,
  ) {}

  initializeFormGroup() {
    this.propertyFormGroup = this.formBuilder.group({
      versionName: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.initializeFormGroup();
    this.isPropertyUpdatedPermission = this.permissionService.hasPermissions([
      this.userRolePermissions.CANVAS_SCENE_DEFINITION_UPDATE_PROPERTIES,
    ]);
  }
  
  get versionInfoFormGroupControls() {
    return this.propertyFormGroup.controls;
  }

  onPropertyClick(propertyType: string = '') {
    if (this.isPropertyUpdatedPermission) {
      this.selectedPropertyKey = propertyType;
      this.canvasDataService.setPropertyTypeTrigger(propertyType);
    }
  }

  clearProperty(propertyType: string) {
    if (this.isPropertyUpdatedPermission && propertyType) {
      const sceneDefintion: any = this.canvasDataService.getSceneDefintion();
      const sceneObjectData = sceneDefintion.sceneObjects.find(
        (sceneObj: ISceneObject) => sceneObj.id === this.componentId,
      );
      if (sceneObjectData?.properties.hasOwnProperty(propertyType)) {
        delete sceneObjectData.properties[propertyType];
      }        this.canvasDataService.updateSceneObjectById(this.componentId, {
        ...sceneObjectData,
      });
      this.canvasDataService.clearPropertyTrigger(propertyType);
    }
  }

  openPropertyModel() {
    this.propertyModal.onOpenModal();
  }

  selectSnapshotId(id:string){
    this.selectedId = id
  }

  onPropertyTypeSelected(propertyType: keyof ISceneObjectProperties) {
    if (!this.properties?.[propertyType]) {
      const activeSceneObject = this.canvasDataService.getSceneDefintion().sceneObjects.find((sceneObj: ISceneObject) => sceneObj.id === this.componentId);
      activeSceneObject.properties[propertyType] = null;
      this.canvasDataService.updateSceneObjectById(activeSceneObject.id, { ...activeSceneObject });
    }
    this.propertyModal.oncloseModal();
  }
}
