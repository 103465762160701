<div *ngIf="moduleType" class="visual-annotation" id="viewer" (keydown)="onKeyDown($event)" #visualPanelContainer>
  <!--  IMAGE VIEWER -->
  @if(!isThumbnailViewer ){
  <div [ngClass]="isFindingAnnotatedClicked ?'image-viewer-finding': 'image-viewer-no-finding'"
    class="visual-annotation_canvas-viewer">

    <!-- =========== Visual Anntotaion 2d component =============-->
    @if(currentSelectedCanvas && !isInsufficientDataResource) {
    <app-visual-canvas-annotation #canvasAnnotationViewer
    [isIframeMode]="iframeMode" [setBackgroundImageSrc]="currentSelectedCanvas.compressedUrl" [thumbnailMode]="false"
    [thumbnailView]="thumbnail2dViewEvent.EXPAND_VIEW"
    [stageProperty]="currentSelectedCanvas.stageProperty ?? null"
    [dataCooridnates]="currentSelectedCanvas.annotationRecords ?? []"
    [selectedItem]="currentSelectedCanvas" [canvasQueryParamsObject]="canvasQueryParamsObject" [isDisplayImage]="true"
    [moduleType]="moduleType"
    (onUpdatedCanvasStageItems)="getTriggerToUpdatedcanvas($event)"
    (onDeleteCanvasAnnotation)="getTriggerToDeletecanvas($event)"
    [modeOfActionClicked]="modeOfActionClicked"
    [splitScreenViewerMode]="splitScreenViewerMode"
    [activeSceneAnnotateIds]="activeSceneAnnotateIds"
    [isOrthoMapPreviewMode]="isOrthoMapPreviewMode"
    [isClickedOpenVisualGallery]="isClickedOpenVisualGallery"
    (onMaxmizedCanvasViewer)="getTriggerToMaxmizedViewer($event)">
    </app-visual-canvas-annotation>
    }
    <!-- ===========  Visual Anntotaion 2d component =========== -->

    <div class="overlay-viewer-icons">
      <!-- VIEWER LOADER -->
      @if (!(moduleType === twinModuleType && aiSnapShotImages.length && !isClickedOpenVisualGallery)) {
        @if (!isInsufficientDataResource) {
          <div *ngIf="showViewCanvasLoader || !currentSelectedCanvas" class="viewer-loader preloader">
            <span class="proload-text"> Fetching from data resource Api..</span>
          </div>
        } @else {
          <div class="viewer-loader"><span class="proload-text">Images not available..</span></div>
  
        }
      }
     
      <!--  VIEWER CLOSED -->
      @if(currentSelectedCanvas && !isOrthoMapPreviewMode) {
        <a class="viewer-close" (click)="onCloseManualAnnotation()">
          <span><i class="fa fa-close arrow-icon" aria-hidden="true"></i></span>
        </a>
      }
       <!--  AUTO PROJECTION -->
       @if( currentSelectedCanvas && moduleType === twinModuleType && !iframeMode) {
        <div class="viewer-auto-projection">
          <span for="reviewed-check"> Auto Projection</span>
           <label for="projectionCheckbox" class="switch" >
            <input id="projectionCheckbox" #check type="checkbox" [checked]="autoTowerProjection" (change)="onChangeAutoProjection($event)" aria-label="Auto Projection" />
            <span class="slider round"></span>
          </label>
        </div>
      }
      <!-- VIEWER REVIEW -->
      @if(moduleType === gridModuleType){
        @if(!showViewCanvasLoader && currentSelectedCanvas && !isFindingAnnotatedClicked && !isInsufficientDataResource && !splitScreenViewerMode && !isOrthoMapPreviewMode && !iframeMode) {
          <a class="viewer-reviewer">
            <input id="reviewed-check" class="custom-checkbox" type="checkbox"
              [(ngModel)]="currentSelectedCanvas.isReviewed" (ngModelChange)="onChangeReviewedDataResourceStatus($event)">
            <label class="reviewer-info" for="reviewed-check"> Reviewed</label>
          </a>
        }
      } @else {
      @if(!isThumbnailViewer && currentSelectedCanvas && !iframeMode){
      <a class="viewer-reviewer">
        <input id="reviewed-check" class="custom-checkbox" type="checkbox"
        [(ngModel)]="currentSelectedCanvas.verify_checked" (ngModelChange)="onCheckStatus($event,currentSelectedCanvas)">
        <label class="reviewer-info" for="reviewed-check"> Verified</label>
      </a>
      }
      }
      <!-- =========== VIEWER FILTER [Dropdown List] ================== -->
      @if(!showViewCanvasLoader && !splitScreenViewerMode && !isOrthoMapPreviewMode && !isFindingAnnotatedClicked && !iframeMode) {
        <div class="viewer-filter">
          <div class="filter-wrap">
              <button class="btn-filter" type="button" (click)="onShowHideToggle()">
                <span class="btn-filter-text">Filter</span>
                <img class="filter-icon" [src]="'/icon/vector.svg' | assetUrl" alt="filter" />
              </button>
            @if(showMenuOnOff) {
              <ul  class="filter-dropdown-menu" aria-labelledby="filter">
                @if (imageFolderTagDropdownList.length){
                @for(item of imageFolderTagDropdownList; track item?.displayName; let i = $index) {
                <li class="filter-dropdown-item" [ngClass]="'filter-dropdown-item-active'">
                  <div class="filter-checkbox-wrapper" (click)="folderOnChangeSelectedDropListEvent(item)">
                    <input type="checkbox" class="custom-checkbox" [(ngModel)]="item.checked" [value]="item.displayName"
                      [id]="'filter-checkbox-'+i" />
                    <span class="filter-item-checkmark"></span>
                  </div>
                  <label [for]="'filter-checkbox-'+i" class="filter-item-text">{{ item?.displayName }}</label>
                </li>
                }
                }@else {
                <span>Loading...</span>
                }
              </ul>
            }
          </div>
        </div>
      }
      <!--  VIEWER ARROW LEFT/RIGHT -->
      @if(!showViewCanvasLoader && !isFindingAnnotatedClicked &&!isInsufficientDataResource) {
        <a class="viewer-arrow-left" (click)="onThumbNailNavClick('left')">
          <span><i class="fa fa-angle-left arrow-icon" aria-hidden="true"></i></span>
        </a>
        <a class="viewer-arrow-right" (click)="onThumbNailNavClick('right')">
          <span><i class="fa fa-angle-right arrow-icon" aria-hidden="true"></i></span>
        </a>
      }
     
    </div>
  </div>
  <!--  THUMBNAIL INFO -->
  @if(!showViewCanvasLoader){
  <div class="visual-annotation_canvas-viewer-info">
    @if(!splitScreenViewerMode || !isOrthoMapPreviewMode){
    <p class="info-text">{{ trimImageName(currentSelectedCanvas?.fileName, 20) }} JPG</p> 
    }
    <p class="info-text">
      {{ momentTimeZone(currentSelectedCanvas?.originRecord?.metadata?.exif?.dateTimeOriginal).format("DD-MM-yyyyhh:mmA") || 'NA' }}
    </p>
    <p class="info-text">
      {{(currentSelectedCanvas?.originRecord?.metadata?.exif?.gpsLatitude | gpsCoordinate) || 'NA'}}
      {{currentSelectedCanvas?.originRecord?.metadata?.exif?.gpsLatitudeRef || 'NA'}},
      {{(currentSelectedCanvas?.originRecord?.metadata?.exif?.gpsLongitude | gpsCoordinate) || 'NA'}}
      {{currentSelectedCanvas?.originRecord?.metadata?.exif?.gpsLongitudeRef || 'NA'}}
    </p>
  </div>
  }
  }
  <!--  THUMBNAIL HORIZONTAL LIST -->
  <div [style.height]="isFindingAnnotatedClicked ? isFindingHeightInitResize : isFindingHeightActual"
    class="visual-annotation_thumbnail-list">
    @if(!isLoadCompletionDSource){
    <a class="thumbnail-loader"> <span><i class="fas fa-spinner fa-pulse spin-icon-loader"
          aria-hidden="true"></i></span></a>
    } @else {
    @if((loadMoreHorzImages.length || aiSnapShotImages.length) && !isFindingAnnotatedClicked){
    <a class="arrow-left" (click)="onThumbNailNavClick('left')">
      <span><i class="fa fa-angle-left arrow-icon" aria-hidden="true"></i></span>
    </a>
    <ul class="thumbnail" id="canvas-all-canvas-img" #listScrollContainer (wheel)="onHorizontalScroll($event)">
      <!-- thumbnail snapsot list --> 
      @if(moduleType === twinModuleType && aiSnapShotImages.length && !isClickedOpenVisualGallery && isThumbnailViewer) {
        @for(items of aiSnapShotImages; track items._id; let i = $index) {
          <li id="snapshotthumbnail-{{i}}" class="thumbnail-item">
            <div class="thumbnail-canvas"   >
              <img [src]="items.src" class="snaphot-image" alt="snaphot"  [ngStyle]="{width: items?.stageProperty?.width +'px', height: items?.stageProperty?.height +'px'}" /> 
          </div>
       </li>
        }
      }
      <!-- =========== Visual Anntotaion 2d component =============-->
      @for(items of loadMoreHorzImages; track items._id; let i = $index) {
      <li id="canvasannotationthumbnail-{{i}}" class="thumbnail-item"
        [ngStyle]="{'display':  items.showTag || items.showAll  ? 'block' : 'none' }">
        <div class="thumbnail-canvas" [ngClass]="{ 'activeHorz': items._id == currentSelectedCanvas?._id}"
          (click)="!isThumbnailViewer ? getPreviewMainByClickImg(items,i) : ''">
          <app-visual-canvas-annotation #annotationPgb [isIframeMode]="false" [setBackgroundImageSrc]="items?.thumbUrl"
            [thumbnailMode]="true" [thumbnailView]="thumbnail2dViewEvent.HORIZONTAL_VIEW"
            [canvasQueryParamsObject]="canvasQueryParamsObject" [stageProperty]="items?.stageProperty ?? null"
            [dataCooridnates]="items?.annotationRecords ?? []" [selectedItem]="items"
            (onUpdatedCanvasStageItems)="getTriggerToUpdatedcanvas($event)" [modeOfActionClicked]="modeOfActionClicked"
            [splitScreenViewerMode]="splitScreenViewerMode" [moduleType]="moduleType"
            [activeSceneAnnotateIds]="activeSceneAnnotateIds"
            [isOrthoMapPreviewMode]="isOrthoMapPreviewMode"
            [isClickedOpenVisualGallery]="isClickedOpenVisualGallery"
            (onDeleteCanvasAnnotation)="getTriggerToDeletecanvas($event)"
            (onMaxmizedCanvasViewer)="getTriggerToMaxmizedViewer($event)">
          </app-visual-canvas-annotation>
          @if(items.isReviewed && !splitScreenViewerMode){ <span class="label-badge review-enable"> Reviewed </span> }
          @if(items.verify_checked){ <span class="label-badge validate-enable"><i class="fa fa-check"
              aria-hidden="true"></i></span> }
          @if(moduleType === twinModuleType && isThumbnailViewer && items?.isLoaded){ <span
            class="label-badge-right expand-enable" (click)="onClickExpandInThumbnailViewer(items,i)"> <img
              [src]="'/icon/maximize.svg' | assetUrl" alt="maximize"> </span> }
          @if(!items?.isLoaded){
          <div class="thumbnail-img-load" [title]="canvasTitleMsg">
            <i class="fas fa-spinner fa-pulse spin-icon-loader" aria-hidden="true"></i>
          </div>
          }
        </div>
      </li>
      }
      <!-- =========== Visual Anntotaion 2d component =============-->
    </ul>
    <a class="arrow-right" (click)="onThumbNailNavClick('right')">
      <span><i class="fa fa-angle-right arrow-icon" aria-hidden="true"></i></span>
    </a>
    }@else {
    <!-- thumbnail loader icon-->
    <a class="thumbnail-loader" [style.left]="noDataRecordFound ? '40%':'50%'">
      @if(noDataRecordFound){
      <span> No annotation found..! </span>
      } @else {
      <span><i class="fas fa-spinner fa-pulse spin-icon-loader" aria-hidden="true"></i></span>
      }
    </a>
    }
    }
  </div>
</div>