import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { API_MESSAGE_EVENTS, CANVAS_GEOMETRIC_TYPE, CANVAS_INPUT_CHANGE_PROPERTY, THUMBNAIL_2D_VIEW_EVENTS, STAGE_2D_CANVAS_COMMON_VIEW, ANNOTATION_DRAWBOX_INIT_PROPERTY, KEYBOARD_PROPERTY, SHARE_PROPERTIES, NEW_BOX_OFFSET_VALUE, TIME_DURATION_MS } from 'projects/annotation-2d/src/app/constants';
import { Annotation2DControl, Annotation2DControlAction, ICanvasImageProperty, ICanvasQueryParams, ICanvasRectangleBox, ICanvasStageProperties, IGeometricPolygon, IPolygonVerticePoints, ISaveAnnotationItems, IStageResourceRecord, IThumbnailInstance, IAiObjectProps, ICurrentSelectedAnnotation } from 'projects/annotation-2d/src/app/interfaces';
import { IGeometryAnnotationDto } from 'projects/annotation-2d/src/app/interfaces/annotation-api-response.interface';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Layer } from 'konva/lib/Layer';
import { Rect } from 'konva/lib/shapes/Rect';
import { Line, LineConfig } from 'konva/lib/shapes/Line';
import { Circle } from 'konva/lib/shapes/Circle';
import { AssetUrlPipe } from 'projects/annotation-2d/src/app/pipes';
import { NotificationService } from 'projects/annotation-2d/src/app/services/notification.service';
import { EventTypes } from 'projects/annotation-2d/src/app/interfaces/notification.interface';
import { AnnotationVisualCanvasService } from 'projects/annotation-2d/src/app/services/annotation-visual-canvas.service';
import { ASSET_SVG_ICONS } from 'projects/annotation-2d/src/app/constants/icon';
import { AssetUrlGlobePipe } from '../../../asset-url-globe.pipe';
import { GlobeConfirmPopupWindowComponent } from '../globe-confirm-popup/globe-confirm-popup.component';
import { FindingsService } from '../../../services/findings.service';
import { UndoAnnotationService } from '../../../services';

@Component({
  selector: 'app-visual-canvas-annotation',
  standalone: true,
  imports: [FormsModule, CommonModule, AssetUrlGlobePipe, GlobeConfirmPopupWindowComponent],
  templateUrl: './visual-canvas-annotation.component.html',
  styleUrl: './visual-canvas-annotation.component.scss',
  providers: [AssetUrlPipe]
})
export class VisualCanvasAnnotationComponent {

  stage: Stage;
  layer: Layer;
  drawBox: any;
  backgroundImgGroup: any;
  backgroundImageData: any = null;
  controlClicked: Annotation2DControl;
  ANNOTATION_2D_CONTROL = Annotation2DControl;
  controlActionClicked: Annotation2DControlAction;
  ANNOTATION_2D_CONTROL_ACTION = Annotation2DControlAction
  transformer: Konva.Transformer | null = null;
  rangeValue: number = STAGE_2D_CANVAS_COMMON_VIEW.RANGE_VALUE;
  isExpanded: boolean = false;
  // input properties
  thumbnail2dViewEvent = THUMBNAIL_2D_VIEW_EVENTS;
  @Input() setBackgroundImageSrc: string;
  @Input() thumbnailMode: boolean;
  @Input() drawMode: string = ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWMODE;
  @Input() dataCooridnates: Array<IGeometryAnnotationDto>;
  @Input() stageProperty: ICanvasStageProperties;
  @Input() selectedItem: IStageResourceRecord;
  @Input() thumbnailView: string;
  @Input() isDisplayImage: boolean | null;
  @Input({ required: true }) moduleType: string;
  @Input() canvasQueryParamsObject: ICanvasQueryParams;
  @Input() modeOfActionClicked: string = ''
  @Input() splitScreenViewerMode: boolean = false;
  @Input() activeSceneAnnotateIds: string[] = [];
  @Input() isOrthoMapPreviewMode: boolean = false;
  @Input() isClickedOpenVisualGallery: boolean;
  @Output() onUpdatedCanvasStageItems: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteCanvasAnnotation: EventEmitter<any> = new EventEmitter();
  projectId = '';
  missionId = '';
  siteId = '';
  workspaceId = '';
  currentActiveShapeId = '';
  currentActiveShapeName = "";
  currentActiveShapeAiTag = false;
  isAddNewRecord = false;
  annotation_payload_type = STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_TYPE;
  viewer_stage_name = STAGE_2D_CANVAS_COMMON_VIEW.THUMB_STAGENAME;
  KnownAnnClassLabels: string[] = [STAGE_2D_CANVAS_COMMON_VIEW.ANTENNA, STAGE_2D_CANVAS_COMMON_VIEW.TRANSCEIVER, STAGE_2D_CANVAS_COMMON_VIEW.HEAD_FRAME_MOUNT, STAGE_2D_CANVAS_COMMON_VIEW.AVIATION_LIGHT, STAGE_2D_CANVAS_COMMON_VIEW.SHELTER]
  @ViewChild('canvasContainer', { read: ElementRef }) canvasContainerRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasLoaderContainer', { read: ElementRef }) canvasLoaderContainerRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('thumbCanvasContainer', { read: ElementRef }) thumbCanvasContainerRef: ElementRef<HTMLCanvasElement>;
  @Input() isIframeMode: boolean;
  thumbnailInvoke = false;
  imageObj: any;
  imagLoad = false
  // polygon properties:
  isDrawingPolygon = false;
  tempPolygon: Konva.Line;
  linePoints: number[] = [];
  mouseDownEventStatus = false;
  lastPt = 0;
  polyTypeStatus = false;
  polyMode = false;
  originalPoints: number[] = [];
  isAnnotationEditPermission = true;
  isAnnotationDeletePermission = true;
  polygonVerticePoints: IPolygonVerticePoints[] = [];
  currentActiveVertice = '';
  @ViewChild('annotationBoxDeleteConfirmDialog') annotationBoxDeleteConfirmDialog: GlobeConfirmPopupWindowComponent; // Delete confirmation
  deleteAnnotationBoxDialogConfig: any = { // Delete confirmation
    title: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_TITLE,
    message: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_CONTENT,
    confirmBtnText: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_DEL_BTN,
    cancelBtnText: STAGE_2D_CANVAS_COMMON_VIEW.DEL_CONFIRM_CANCEL_BTN
  }
  blobUrls: string[] = [];
  blobUrlIndex = 0;
  lowResLayer: Layer;
  lowResbackgroundImageData: Konva.Image | null | Konva.ImageConfig = null;
  roundedPercentage = 0;
  completeChunkStatus = false;
  animationFrameId: number | null;
  evtDrawBox: Konva.KonvaEventObject<MouseEvent> | { evt: any } | null
  isExpandViewFirstTime = false;
  progressivePrecentage = 0;
  progressivePrecentLoad = false;
  zoomValue = 0;
  maxZoomValue = 2000;
  minZoomValue = 0;
  zoomScalevalue = 1.05;
  isAnnotatedTagByAi = false;
  xhr: any;
  currentSelectedAnnotation: ICurrentSelectedAnnotation | null = null;
  currentActiveFindingId = '';
  currentLayoutSize: { width: number, height: number } | null = null;
  drawNewPointCursor: string;
  disableClickEvents = false;
  offsetValue = NEW_BOX_OFFSET_VALUE;
  isUndo = false
  showModeStatus = false; //hide edit icons
  constructor(
    private notificationService: NotificationService,
    private annotationVisualCanvasService: AnnotationVisualCanvasService,
    private assetUrlPipe: AssetUrlPipe,
    private findingsService: FindingsService,
    private undoAnnotationService: UndoAnnotationService
  ) { this.customSetCursorPointers();
    this.annotationVisualCanvasService.imageViewerExpandObservable$.subscribe((value: boolean) => {
      this.isExpanded = value
    }); }

  customSetCursorPointers() {
    const drawNewPointer = this.assetUrlPipe.transform(ASSET_SVG_ICONS.NEW_ANNOTATE_POINTER);
    const img = new Image();
    img.src = drawNewPointer;
    img.onload = () => {
      this.drawNewPointCursor = `url(${drawNewPointer}) ${img.width / 2} ${img.height / 2}, auto`;
    };
  }

  // Onclick Image preview canvas:
  ngOnChanges(changes: SimpleChanges) {
    this.onChangePropertyItems(changes);
  }

  ngOnInit(): void {
    if (this.selectedItem) this.selectedItem.isVerticed = false;
    this.currentActiveVertice = '';
  }

  //** Dynamic Thumbnail image viewer list canvas- */
  ngAfterViewInit(): void {
    const containerElement = this.stageProperty ? this.thumbCanvasContainerRef.nativeElement.querySelector("#" + this.stageProperty.id) : null;
    // Loaded once afterViewInit :on thumbnail mode 
    if (containerElement && this.thumbnailMode && this.selectedItem) this.canvasThumbDynamicInstance()
    if (this.isDisplayImage) {
      window.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.resetShapeOnClicked(); // If click to start draw, Reset the cursor pointer back to normal
          this.clearTemporaryPolyLineShapes(); // Reset/destroy line when escape pressed 
          if (this.annotationBoxDeleteConfirmDialog) this.annotationBoxDeleteConfirmDialog.handleModalDisplay(false); //if open close modal
          this.onResetDrawBoxWithClosedFindingPanel();
        }
      });
    }
  }

  // onchange property items:
  onChangePropertyItems(changes: SimpleChanges) {
    for (let inputItem in changes) {
      switch (inputItem) {
        case CANVAS_INPUT_CHANGE_PROPERTY.CANVAS_QUERY:
          this.onChangeGetQueryParams(inputItem, changes);
          break;
        // ** 2.OnSelect vertical thumbnail => reflect to Image Viewer
        case CANVAS_INPUT_CHANGE_PROPERTY.SELECTED_ITEM:
          this.onChangeCanvasSelectItem(inputItem, changes);
          break;
        case CANVAS_INPUT_CHANGE_PROPERTY.ACTIVE_SCENE_ANNOTATE_IDS:
          if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) {
            const imageThumbnailViewer = this.annotationVisualCanvasService.imageThumbnailViewerModeSubject.getValue();
            if (imageThumbnailViewer.isThumbnailViewer) this.onChangeScenceDefinitionTab();
          }
          break;
        case CANVAS_INPUT_CHANGE_PROPERTY.STAGE_PROPERTY:
          if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) this.onChangeScenceDefinitionTab();
          break;
      }
    }
  }

  //** onChange Query params: */
  onChangeGetQueryParams(inputItem: string, changes: SimpleChanges) {
    if (changes[inputItem].currentValue) {
      let canvasQueryParamsObject: ICanvasQueryParams = changes['canvasQueryParamsObject'].currentValue;
      this.projectId = canvasQueryParamsObject?.projectId ?? "";
      this.missionId = canvasQueryParamsObject?.missionId ?? "";
      this.siteId = canvasQueryParamsObject?.siteId ?? "";
      this.workspaceId = canvasQueryParamsObject?.workspaceId ?? "";
    }
  }

  // ** OnSelect vertical thumbnail => reflect to Image Viewer
  async onChangeCanvasSelectItem(inputItem: string, changes: SimpleChanges) {
    if (changes[inputItem].currentValue) {
      if (this.thumbnailView === THUMBNAIL_2D_VIEW_EVENTS.EXPAND_VIEW) {
        //getStage and rect clear :canvas viewer
        this.selectedItem = changes[inputItem].currentValue;
        await this.annotationVisualCanvasService.removeAllAnnotateStageViewer()
        await this.annotationVisualCanvasService.destroyAllExpandViewStageCanvas();
        await this.canvasInit();
      }
    }
  }

  //** Scence Definition onChange tab */
  async onChangeScenceDefinitionTab() {
    if (this.thumbnailView != THUMBNAIL_2D_VIEW_EVENTS.EXPAND_VIEW) { //&& imageThumbnailViewer.isThumbnailViewer
      await this.annotationVisualCanvasService.removeAllThumbnailBox();
      await this.annotationVisualCanvasService.destroyAllThumbViewStageCanvas();
      await this.canvasThumbDynamicInstance();
    } else {
      this.annotationVisualCanvasService.updateThumbnailStage(this.stageProperty); //need to stageSize
    }
  }

  // Canvas Viewer Set background image
  // canvas initilaize
  async canvasInit() {
    this.isExpandViewFirstTime = true;
    this.progressivePrecentage = 0;
    if (this.selectedItem) {
      const elementLoader = this.canvasLoaderContainerRef.nativeElement;
      elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_BLOCK;
      // first we need to create a stage
      this.stage = new Konva.Stage({
        container: '.canvas-container',
        width: this.canvasContainerRef.nativeElement.offsetWidth,
        height: this.canvasContainerRef.nativeElement.offsetHeight,
      });
      // Set the ID attribute of the stage using the setAttr method
      this.stage.setAttr('id', STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_CONTAINER);
      this.stage.setAttr('name', STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_CONTAINER);
      this.stage.setAttr('RefId', STAGE_2D_CANVAS_COMMON_VIEW.CANVAS_VIEWER_REF + this.selectedItem._id);
      // then create layer
      this.layer = new Konva.Layer();
      this.stage.add(this.layer);
      this.imagLoad = true
      await this.setBackgroundImage(elementLoader);
      if (!this.thumbnailMode) this.canvasZoomInOut();
    }
  }

  // start progressive:
  // New update method set background:
  async setBackgroundImage(elementLoader: HTMLCanvasElement) {
    elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_BLOCK;
    if (this.selectedItem?.compressedUrl) {
      if (this.imageObj) {
        this.imageObj.remove();
        this.imageObj = null;
      }
      this.backgroundImgGroup = new Konva.Group({
        draggable: !this.thumbnailMode,
      });
      this.layer.add(this.backgroundImgGroup);
      // Fetch the image progressively low to high
      this.lowResLayer = new Konva.Layer();
      if (this.xhr) this.xhr.abort();
      this.onProgressiveLowResImage(elementLoader);
      this.onProgressiveOnLoadHighResImage(elementLoader);
    } else {
      elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_BLOCK;
    }
  }

  // on progressive low resolution
  onProgressiveLowResImage(elementLoader: HTMLCanvasElement) {
    if (this.animationFrameId) {
      elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_NONE;
      cancelAnimationFrame(this.animationFrameId);
    }
    if (this.selectedItem?.thumbUrl) {
      const lowResImageSrc = this.selectedItem.thumbUrl;
      let clientWid = this.canvasContainerRef.nativeElement.offsetWidth
      let clientHeight = this.canvasContainerRef.nativeElement.offsetHeight
      this.stage.width(clientWid);
      this.stage.height(clientHeight);
      this.stage.draw();
      let lowResImage = new Image();
      lowResImage.src = lowResImageSrc;
      let aspectLowResWidth = ANNOTATION_DRAWBOX_INIT_PROPERTY.PROGRESSIVE_BLUR_STAGE_WID //Number(this.stage.width() * aspectRatio)    
      let aspectHeigResHeight = this.stage.width() + ANNOTATION_DRAWBOX_INIT_PROPERTY.PROGRESSIVE_BLUR_AJST_HEIGHT;
      let imgProperties: ICanvasImageProperty = {
        image: lowResImage,
        width: aspectLowResWidth, //this.stage.width(), 
        height: aspectHeigResHeight, //this.stage.width(),
        opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY_INIT, // Start with opacity set to 0 for animated layer
      }
      let lowResbackgroundImage: Konva.Image = new Konva.Image(imgProperties);
      // Once the image is loaded, set it as the background image
      lowResImage.onload = () => {
        this.progressivePrecentLoad = true;
        this.lowResLayer.add(lowResbackgroundImage);
        this.stage.add(this.lowResLayer);
        // Create a background image
        lowResbackgroundImage.image(lowResImage);
        const zoomOutFactor = STAGE_2D_CANVAS_COMMON_VIEW.SET_THUMB_BGIMAGE_ZOOM_FACTOR; // Adjust as needed
        this.centerScalingGroup(lowResbackgroundImage, zoomOutFactor, lowResbackgroundImage);
        this.stage.draw();
        this.lowResLayer.batchDraw();
        this.backgroundImageData = lowResbackgroundImage;
        this.lowResbackgroundImageData = lowResbackgroundImage
        this.completeChunkStatus = false;
        elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_BLOCK;
      };
    }
  }

  onProgressiveOnLoadHighResImage(elementLoader: HTMLCanvasElement) {
    // Define the URL of the large background image  
    let highResImageUrl = this.selectedItem.compressedUrl //this.selectedItem.originUrl;
    // on progressive low to high resolution:
    const _this = this;
    this.xhr = new XMLHttpRequest();
    this.xhr.open('GET', highResImageUrl, true);
    this.xhr.responseType = STAGE_2D_CANVAS_COMMON_VIEW.BLOB;
    // img on processing event:
    this.xhr.onprogress = (e: any) => {
      if (e.lengthComputable) {
        const percentage = (e.loaded / e.total) * 100;
        this.progressivePrecentage = Math.ceil(percentage);
        if (this.progressivePrecentage === 100) this.progressivePrecentLoad = false;
        this.lowResLayer.batchDraw();
        this.imagLoad = false
      }
    };
    // img on loaded event:
    this.xhr.onload = function () {
      if (this.status === 200) {
        if (_this.selectedItem?.compressedUrl) {
          _this.selectedItem.diskCache = true;
          const blob = this.response;
          const blobUrl = URL.createObjectURL(blob);
          _this.imagLoad = true;
          _this.imageObj = new Image();
          _this.imageObj.src = blobUrl;
          if (_this.imagLoad) {
            _this.imageObj.onload = function () {
              _this.imagLoad = false;
              // Create a background image
              let imgProperties: ICanvasImageProperty = {
                image: _this.imageObj,
                width: _this.imageObj.width,
                height: _this.imageObj.height,
                opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY_INIT, // Start with opacity set to 0 for animated layer
                name: 'canvasImage',
              }
              let backgroundImage: Konva.Image = new Konva.Image(imgProperties);
              // Cache the background image
              backgroundImage.cache();
              _this.backgroundImgGroup = new Konva.Group({
                draggable: !_this.thumbnailMode,
                name: 'canvasImageGroup', // Set a name for the group
              });
              let findZScale = backgroundImage.width() > STAGE_2D_CANVAS_COMMON_VIEW.LARGE_IMAGESIZE ? STAGE_2D_CANVAS_COMMON_VIEW.SET_MAIN_BGIMAGE_ZOOM_FACTOR : STAGE_2D_CANVAS_COMMON_VIEW.SET_MAIN_BGIMAGE_ZOOM_FACTOR_SMALL;
              _this.backgroundImgGroup.add(backgroundImage);
              _this.centerScalingGroup(_this.backgroundImgGroup, findZScale, backgroundImage);
              _this.layer.add(_this.backgroundImgGroup);
              _this.stage.add(_this.layer);
              _this.backgroundImgGroupEventHandler(); //group
              _this.lowResLayer.destroy(); // Remove the low-res layer after the animation
              // Redraw the stage to apply the changes
              _this.stage.draw();
              _this.backgroundImageData = backgroundImage;
              let canvasInstance: IThumbnailInstance = {
                stage: _this.stage,
                layer: _this.layer,
                bgGroup: _this.backgroundImgGroup,
              }
              if (!_this.thumbnailMode) _this.getAllDrawBoxes(canvasInstance, _this.dataCooridnates, imgProperties);
              elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_NONE;
              _this.progressivePrecentLoad = false;
              if (!this.thumbnailMode) _this.annotationVisualCanvasService.setActivebackgroundImgGroup(_this.backgroundImgGroup);
              if (_this.xhr) _this.xhr.abort();
              _this.calculateOverallZoom();
            };
          }
        } else {
          elementLoader.style.display = STAGE_2D_CANVAS_COMMON_VIEW.VIEWER_LOADER_BLOCK;
        }
      } else {
        console.error('Image could not be loaded.');
      }
    };
    this.xhr.send();
  }

  //Center Scaling Group:
  centerScalingGroup(backgroundImgGroup: Konva.Image, zoomFactorValue: number, backgroundImage: Konva.Image) {
    backgroundImgGroup.offset({ x: backgroundImage.width() / 2, y: backgroundImage.height() / 2 });
    backgroundImgGroup.position({ x: this.stage.width() / 2, y: this.stage.height() / 2 });
    // Calculate the new scale for the group
    const newScaleX = backgroundImgGroup.scaleX() * zoomFactorValue;
    const newScaleY = backgroundImgGroup.scaleY() * zoomFactorValue;
    // Apply the new scale to the group
    backgroundImgGroup.scaleX(newScaleX);
    backgroundImgGroup.scaleY(newScaleY);
    this.layer.batchDraw();
  }

  calculateOverallZoom() {
    const stageScaleX = this.stage.scaleX();
    const layerScaleX = this.layer.scaleX();
    this.zoomValue = Math.round((stageScaleX * layerScaleX) * 100);
  }

  // resize stage
  resizeStage() {
    this.stage.width(this.canvasContainerRef.nativeElement.clientWidth);
    this.stage.height(this.canvasContainerRef.nativeElement.clientHeight);
    this.stage.draw();
  }

  //** Thumbnail Background imageImage Viewer  */
  // canvas Dynamic initilaize
  async canvasThumbDynamicInstance() {
    if (this.stageProperty && this.selectedItem) {
      const respData = this.annotationVisualCanvasService.createStageAndLayerForThumb(this.stageProperty, this.viewer_stage_name);
      if (respData?.stage && respData?.layer) {
        const getThumbInstance: IThumbnailInstance = { stage: respData.stage, layer: respData.layer };
        this.annotationVisualCanvasService.setBackgroundImageAsThumbnail(getThumbInstance, this.setBackgroundImageSrc, this.thumbnailView, this.thumbnailMode)
          .then((result) => {
            if (result) this.getAllDrawBoxes(result.canvasInstance, this.dataCooridnates, result.thumbBgImageProperties);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  // -------------------------------------------------------------------------------------------------------------
  // Get All annotation box
  getAllDrawBoxes(canvasInstance: IThumbnailInstance, dataCooridnates: Array<IGeometryAnnotationDto>, imgProperties: ICanvasImageProperty) {
    const cooridnates = this.isClickedOpenVisualGallery ? dataCooridnates.filter(({_id}: IGeometryAnnotationDto)=>(this.activeSceneAnnotateIds.includes(_id!))) : dataCooridnates
    if (cooridnates && cooridnates.length > 0) {
      for (let data of cooridnates) {
        let pointsCoordinate = data.geometry.coordinates;
        this.polyTypeStatus = true;
        if (pointsCoordinate && pointsCoordinate.length > 0) this.getDrawPolygonBox(data, canvasInstance, imgProperties);
      }
    }
    this.selectedItem.isLoaded = true;
  }

  getDrawPolygonBox(data: IGeometryAnnotationDto, canvasInstance: IThumbnailInstance, imgProperties: ICanvasImageProperty) {
    let stage = canvasInstance.stage;
    let layer = canvasInstance.layer;
    let group = canvasInstance.bgGroup;
    let pointsCoordinate: [number, number][] = data.geometry.coordinates;
    let calculatedPoints: [number, number][] = this.annotationVisualCanvasService.getConvertPolygonImageBasedXY(imgProperties, pointsCoordinate);
    let annotatedTagBy = data.tags ?? [];
    this.isAnnotatedTagByAi = annotatedTagBy?.includes(STAGE_2D_CANVAS_COMMON_VIEW.AI_TAG) || false;
    let classAiComponentName = data.classLabels && data.classLabels.length > 0 ? data.classLabels[0] : "antenna";
    let findingId = data.findingId ?? ''; //get tab from left panel use future (data.assetFindings: stored)
    // Flatten the nested points into a single array points:
    const singleArPolyPoints: number[] = this.annotationVisualCanvasService.getSingleFlatArray(calculatedPoints); //original points
    let polygonProperties = {
      points: singleArPolyPoints,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
      stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
      strokeWidth: this.thumbnailMode ? ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_THUMB : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      closed: this.polyTypeStatus,
      hitStrokeWidth: this.thumbnailMode ? ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_THUMB : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME,
      id: data['_id'], // Access the ID of the polygon
      draggable: false,
    }
    const polygon = new Konva.Line(polygonProperties);
    polygon.setAttr('newRefPoint', singleArPolyPoints);
    polygon.setAttr('aiTag', this.isAnnotatedTagByAi);
    polygon.setAttr('findingId', findingId);
    polygon.setAttr('componentName', this.isAnnotatedTagByAi ? classAiComponentName : '');
    polygon.setAttr('backgroundImgGroup', group);
    // shapeGroup: creation ---------
    let shapeGroup = new Konva.Group({
      draggable: false,
      name: 'shapeGroup', // Set a name for the group
      id: polygon.id()
    });
    shapeGroup.add(polygon);
    // -----------------------------------------------------------
    let aiObjectProps: IAiObjectProps = {
      drawBox: polygon,
      backgroundImgGroup: group,
      isAnnotatedTagByAi: this.isAnnotatedTagByAi
    }
    if (this.isAnnotatedTagByAi) { // annotation tool tip only marked by Ai  
      this.annotationVisualCanvasService.aiTagRelatedAnnotationConfiguration(aiObjectProps);
      // #tooltip create config for shape only for canvas viewer 
      if (!this.thumbnailMode) this.annotationVisualCanvasService.createToolTipLabelForShape(polygon, group, shapeGroup);  //#toolTip not shown thumb
    } else {
      this.annotationVisualCanvasService.aiAnnotationMarkedPrimaryStroke(polygon);
    }
    // -----------------------------------------------------------
    group.add(shapeGroup);
    layer.add(group);
    stage.add(layer);
    this.polyTypeStatus = false;
    // this.linePoints = singleArPolyPoints
    if (this.thumbnailView != THUMBNAIL_2D_VIEW_EVENTS.HORIZONTAL_VIEW) this.annotationVisualCanvasService.getFadeInAnimatedRectBox(polygon);
    if (!this.thumbnailMode && !this.isOrthoMapPreviewMode) this.drawBoxEventHandler(polygon);

  }

  // Canvas Viewer
  // end progressive
  updateStageWidth(currentLayoutSize: { width: number, height: number }) {
    if (this.stage) {
      this.currentLayoutSize = currentLayoutSize;
      if (this.currentLayoutSize && this.stage && this.backgroundImageData) {
        const width = currentLayoutSize.width - STAGE_2D_CANVAS_COMMON_VIEW.AVOID_INIT_SCROLL; //avoid scroll horizontal:
        //@ts-ignore
        this.stage.setWidth(width);
        this.centerScalingGroup(this.backgroundImgGroup, STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_LEVEL_INIT, this.backgroundImageData)
      }
    }
  }

  resetShapeOnClicked() {
    this.controlClicked = Annotation2DControl.NO_ACTIVE_CONTROL;
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_POINTER;
    this.annotationVisualCanvasService.setActiveFindingPanel(false); // reset finding panel to close
    this.backgroundImgGroup.draggable(true);
  }

  // OnClick icon DrawBOx Rectangle / polygon
  drawRectBox() {
    this.disableClickEvents = true;
    this.resetShapeOnClicked();
    // set default stroke active or remove actove:
    this.onSelectDrawBoxReset();
    this.controlClicked = Annotation2DControl.RECT;
  }

  //Draw Polygon:
  onClickedPolygon() {
    this.resetShapeOnClicked();
    this.resetDrawPolyLineProperties();
    this.onSelectDrawBoxReset();
    if (this.drawBox) this.drawBox = null;
    this.controlClicked = Annotation2DControl.POLYLINE;
    this.polyTypeStatus = true;
    this.polyMode = true;
  }

  // Poly props:*******************
  mouseDownCreatedPolygon() {
    this.isDrawingPolygon = true;
    if(this.controlClicked === Annotation2DControl.POLYLINE){
      this.backgroundImgGroup.draggable(false);
    }
    const { x, y } = this.backgroundImgGroup.getRelativePointerPosition();
    if (this.polyMode === true) {
      if (this.linePoints.length > 1) { this.linePoints.splice(-2, 2); } //remove the one of the duplicate start and end pts.
      this.linePoints.push(x, y);
      this.tempPolygon = this.annotationVisualCanvasService.drawPolygon(this.stage, this.layer, this.backgroundImgGroup, this.linePoints);
      this.lastPt = this.linePoints.length;
      this.mouseDownEventStatus = true;
    }
  }

  clearTemporaryPolyLineShapes() {
    const polyLineGroupChildren = this.backgroundImgGroup.find('.' + ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME_TEMP);
    if (polyLineGroupChildren && polyLineGroupChildren.length > 0) {
      polyLineGroupChildren.forEach((polyline: Konva.Line) => polyline.remove());
      this.layer.draw();
    }
  }

  resetDrawPolyLineProperties() {
    this.polyMode = false;
    this.lastPt = 0;
    this.mouseDownEventStatus = false;
    this.linePoints = [];
    this.isDrawingPolygon = false;
    this.controlClicked = Annotation2DControl.NO_ACTIVE_CONTROL;
  }
  // ------------------------------------------------------------------------------------------
  // Background image group Handler 
  // Add an annotated marker to canvas image
  backgroundImgGroupEventHandler() {
    this.backgroundImgGroup.on('mousedown', (e: Konva.KonvaEventObject<MouseEvent>) => this.bgImageMouseDownHandler(e));
    this.backgroundImgGroup.on('mousemove', () => this.bgImageMouseMoveHandler());
    this.backgroundImgGroup.on('mouseup', (e: Konva.KonvaEventObject<MouseEvent>) => this.bgImageMouseUpHandler(e));
    this.backgroundImgGroup.on('click', (event: Konva.KonvaEventObject<MouseEvent>) => {
      if (event.target.name() === 'poly' || this.disableClickEvents) return;
      this.onResetDrawBoxWithClosedFindingPanel();
    });
    this.backgroundImgGroup.on('contextmenu', (e: Konva.KonvaEventObject<MouseEvent>) => this.onBgContextmenuClickHandler(e)); //right Click
  }

  bgImageMouseDownHandler(e:Konva.KonvaEventObject<MouseEvent>) {
    if(this.controlClicked && e?.evt?.button === 1){
      this.backgroundImgGroup.draggable(true);
    }else if(e?.evt?.button === 0){
      if(this.controlClicked === Annotation2DControl.RECT){
        this.mouseDownCreatedRectBox();
      }else{
        this.mouseDownCreatedPolygon(); //Poly line is the default case
      }
    }
  }

  bgImageMouseMoveHandler() {
    if (this.controlClicked === Annotation2DControl.RECT) document.body.style.cursor = this.drawNewPointCursor;
    if (this.controlClicked === Annotation2DControl.POLYLINE) {
      document.body.style.cursor = this.drawNewPointCursor;
      if (this.polyMode && this.mouseDownEventStatus) {
        const { x, y } = this.backgroundImgGroup.getRelativePointerPosition();
        this.tempPolygon.points()[this.lastPt] = x;
        this.tempPolygon.points()[this.lastPt + 1] = y;
        this.layer.draw();
      }
    }
  }

  bgImageMouseUpHandler(e: Konva.KonvaEventObject<MouseEvent>) {
    e.evt.preventDefault();
    this.isDrawingPolygon = false;
    if(this.controlClicked === Annotation2DControl.POLYLINE && e.evt.button === 1){
      this.backgroundImgGroup.draggable(false);
    }
  }

  // **********************************
  //Create box position on zoomInOut & Drag :
  mouseDownCreatedRectBox() {
    let linePoints: number[] = []
    let rectPoints = this.annotationVisualCanvasService.getInitDefalutCreatePolygonPoints(this.backgroundImgGroup)
    linePoints = rectPoints.flatMap(point => [point.x, point.y])
    this.createNewPolygon(linePoints);
  }

  onBgContextmenuClickHandler(e: Konva.KonvaEventObject<MouseEvent>) {
    // Prevent the default context menu from showing
    e.evt.preventDefault();
    if (this.controlClicked === Annotation2DControl.POLYLINE) {
      if (this.linePoints && this.linePoints.length > STAGE_2D_CANVAS_COMMON_VIEW.POLYGON_REQUIRED_LENGTH) {
        this.clearTemporaryPolyLineShapes();
        this.createNewPolygon(this.linePoints);
      } else {
        this.clearTemporaryPolyLineShapes();
        this.resetDrawPolyLineProperties();
        this.notificationService.showToast({
          type: EventTypes.error,
          message: API_MESSAGE_EVENTS.GENERIC_DRAW_MESSAGE,
        });
      }
    }
  }

  createNewPolygon(linePoints: number[]) {
    this.backgroundImgGroup.draggable(true);
    let isZoomed = this.stage.scaleX();
    this.drawBox = new Konva.Line({
      points: linePoints,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
      stroke: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
      strokeWidth: (isZoomed >= 1) ? this.annotationVisualCanvasService.calculateStrokeWidth(this.stage) : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      draggable: false,
      closed: true,
      hitStrokeWidth: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME,
    });
    let drawBoxGenerateTempId = this.annotationVisualCanvasService.generateRandomId(5);
    // shapeGroup: creation ---------
    let shapeGroup = new Konva.Group({
      draggable: false,
      name: 'shapeGroup', // Set a name for the group
      id: drawBoxGenerateTempId
    });
    shapeGroup.add(this.drawBox);
    // ------------------------------
    this.layer.add(this.backgroundImgGroup);
    this.stage.add(this.layer);
    this.layer.batchDraw();
    this.drawBox.setAttr('newRefPoint', linePoints);
    this.drawBox.setAttr('aiTag', false);
    this.drawBox.setAttr('componentName', '');
    this.drawBox.setAttr('findingId', '');
    this.drawBox.setAttr('backgroundImgGroup', this.backgroundImgGroup);
    this.drawBox.setAttr('tempId', drawBoxGenerateTempId);
    this.updatePolygonProperty(this.drawBox, linePoints);
    this.resetDrawPolyLineProperties();
    // #tooltip tip create config : polygon : only ai annotation : manual not use:
    if (this.drawBox?.getAttr("aiTag")) {
      this.annotationVisualCanvasService.createToolTipLabelForShape(this.drawBox, this.backgroundImgGroup, shapeGroup);
      this.annotationVisualCanvasService.toolTipGroupShowHideFindByDrawBox(this.drawBox, false, this.backgroundImgGroup); //#tooltip current hide:
    }
    // ------------------------------------------
    this.backgroundImgGroup.add(shapeGroup);
    this.drawBoxEventHandler(this.drawBox);
    // onActive control:
    this.onDrawBoxCurrentActive(null, this.drawBox);
  }

  // =======================================Click Action Handler:==================================
  // 1.onClick DrawBox:
  onDrawBoxCurrentActive(evt: Konva.KonvaEventObject<MouseEvent> | { evt: any } | null, drawBox: Konva.Rect | Konva.Line) {
    if (this.controlClicked === Annotation2DControl.POLYLINE) return;
    this.controlActionClicked = this.ANNOTATION_2D_CONTROL_ACTION.NO_ACTIVE_CONTROL;
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_POINTER;
    this.drawBox = drawBox;
    this.isUndo = this.undoAnnotationService.updateUndoButtonStatus(drawBox.id())
    this.evtDrawBox = evt;
    this.onResetResizeAndVerticeEvent();
    this.drawBox.stroke('black');
    // optional strokeWidth update to selectedItem:
    let isZoomed = this.stage.scaleX();
    if (isZoomed >= 1) {
      this.drawBox.strokeWidth(this.annotationVisualCanvasService.calculateStrokeWidth(this.stage));
    }
    this.currentActiveMarkedAnnotation();
    const pos = this.annotationVisualCanvasService.detectOnVerticePosition(drawBox);
    if (pos) {
      drawBox.setAttr('draggable', false);
      this.onSelectDrawBoxRotateShape(); // onClick Active Box Apply Both [rotation with resize]
    } else {
      drawBox.setAttr('draggable', !this.thumbnailMode);
    }
    if (evt?.evt?.button === 2) { // Right-click detected or select draw polygon 
      this.onSelectDrawBoxVerticeShape(); // onClick Active Box [polygon vertices] 
    }
  }

  onCopyAnnotationBox(drawBox: Konva.Rect | Konva.Line){
    const currentBoxPoints = drawBox.getAttr('newRefPoint')
    const newPoints = currentBoxPoints.map((currentPoint:number)=>currentPoint + this.offsetValue)
    this.disableClickEvents = true;
    this.resetShapeOnClicked();
    this.onSelectDrawBoxReset();
    this.resetDrawPolyLineProperties();
    //this timeout is used to queuing the execution of createNewPolygon
    setTimeout(() => {
      this.createNewPolygon(newPoints);
    }, TIME_DURATION_MS);
  }
  // currently selected annotation:
  currentActiveMarkedAnnotation() {
    if (!this.drawBox?.id()) return;
    
    const {gpsLatitude, gpsLongitude} = this.selectedItem;

    const currentSelectedAnnotation = {
      annotationId: this.drawBox.id(),
      selectedItem: this.selectedItem,
      drawBox: this.drawBox,
      findingId: this.drawBox.getAttr('findingId'),
      projectId: this.projectId,
      missionId: this.missionId,
      siteId: this.siteId,
      workspaceId: this.workspaceId,
      coordinates: [gpsLongitude, gpsLatitude]
    };
    if (!currentSelectedAnnotation.findingId) {
      this.findingsService.findingsList.find(({ annotations, _id }) => {
        if (
          annotations.find(
            (id) => currentSelectedAnnotation.annotationId === id,
          )
        ) {
          currentSelectedAnnotation.findingId = _id;
        }
      });
    }
    this.findingsService.selectedFindingId.next(
      currentSelectedAnnotation?.findingId ?? '',
    );
    this.annotationVisualCanvasService.setActiveMarkedAnnotation(
      currentSelectedAnnotation,
    ); //(include open/cose finding panel)
  }
  // reset shape event:
  onResetResizeAndVerticeEvent() {
    // set default stroke active:
    if (this.drawBox) this.annotationVisualCanvasService.setDefaultStrokeAllShapeInGroup(this.backgroundImgGroup)
    // if transform applied remove
    if (this.transformer) {
      this.transformer.destroy();
      this.transformer = null;
    }
    // remove active drag vertices:
    this.removeAllVerticeFromGroup();
  }
  // 2.onClick DrawBox rotate/resize button:
  onSelectDrawBoxRotateShape() {
    this.controlActionClicked = this.annotationVisualCanvasService.onSelectDrawBoxRotateOrResizeShape(this.transformer, this.ANNOTATION_2D_CONTROL_ACTION.ROTATE)
    if (this.isAnnotationEditPermission) this.onRightClickTransform(this.drawBox)
  }
  // 3.onClick drawBox drag-draw vertices button:
  onSelectDrawBoxVerticeShape() {
    this.controlActionClicked = this.ANNOTATION_2D_CONTROL_ACTION.VERTICE
    if (this.isAnnotationEditPermission) this.onDrawBoxLeftClickVertices(this.drawBox)
  }
  // 4.onclick Remove annotation:
  onSelectDrawBoxRemove() {
    this.controlActionClicked = this.ANNOTATION_2D_CONTROL_ACTION.DELETE;
    if (this.isAnnotationDeletePermission) this.onConfirmToRemoveAnnotation(this.drawBox)
  }
  // Reset annotation:
  onSelectDrawBoxReset() {
    this.drawBox?.setAttr('draggable', false);
    this.controlActionClicked = this.ANNOTATION_2D_CONTROL_ACTION.NO_ACTIVE_CONTROL;
    this.evtDrawBox = null;
    if (this.drawBox) this.drawBox.getAttr('aiTag') ? this.drawBox.stroke(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE_RED) : this.drawBox.stroke(ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE);
    this.drawBox = null;
    this.onResetResizeAndVerticeEvent();
    this.isUndo = false
  }
  // onclick reset & close finding panel:
  onResetDrawBoxWithClosedFindingPanel() {
    this.onSelectDrawBoxReset();
    if (!this.drawBox) this.annotationVisualCanvasService.setActiveFindingPanel(false); //default
  }
  // End action handler:
  // DrawBox Handler :
  drawBoxEventHandler(drawBox: Line) {
    if (this.isIframeMode) return;
    drawBox.on('mouseover', (e: Konva.KonvaEventObject<MouseEvent>) => this.onDrawBoxMouseOverHandler(e, drawBox));
    drawBox.on('mousemove', () => this.onDrawBoxMouseMoveHandler(drawBox));
    drawBox.on('mouseout', () => this.onDrawBoxMouseOutHandler());
    drawBox.on('click', (evt: { evt: { preventDefault: () => void; }; }) => { if (this.isAnnotationEditPermission) { this.onDrawBoxCurrentActive(evt, drawBox) } });
    drawBox.on('dblclick', () => { if (this.isAnnotationDeletePermission) { this.onDrawBoxDoubleClickHandler(drawBox) } });
    drawBox.on('transformstart', (e: Konva.KonvaEventObject<MouseEvent>) => this.onDrawBoxTransformStartHandler(e, drawBox));
    drawBox.on('transformend', (e: Konva.KonvaEventObject<MouseEvent>) => this.onDrawBoxTransformHandler(e, drawBox));
    drawBox.on('dragend', (e: Konva.KonvaEventObject<MouseEvent>) => { if (this.isAnnotationEditPermission) this.onDrawBoxDrawEndHandler(e, drawBox) });
    drawBox.on('dragmove', (e: Konva.KonvaEventObject<MouseEvent>) => { if (this.isAnnotationEditPermission) this.onDrawBoxDrawMoveHandler(e, drawBox) });
    document.addEventListener('keydown', (e: { key: string; keyCode?: number; }) => this.onDrawBoxKeyDownHandler(e));
  }

  onDrawBoxMouseMoveHandler(drawBox: Line) {
    if (this.controlClicked === Annotation2DControl.POLYLINE) return;
    this.annotationVisualCanvasService.onDrawBoxMouseMoveHandlerServ(drawBox);
  }

  onDrawBoxDrawMoveHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    if (this.selectedItem.isVerticed) this.removeCircleFromGroup(`circle_${drawBox.id()}`);
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.annotationVisualCanvasService.toolTipGroupShowHideFindByDrawBox(drawBox, false, this.backgroundImgGroup); //#tooltip current hide: 
  }

  onDrawBoxDrawEndHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_MOVE;
    this.removeActiveVertices(drawBox) //previous active vertices
    // Drag movement has ended
    const shapeName = drawBox.name();
    switch (shapeName) {
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME: {
        const newX = drawBox.x() + drawBox.width();
        const newY = drawBox.y() + drawBox.height();
        drawBox.setAttr('points', [drawBox.x(), drawBox.y(), drawBox.width(), drawBox.y(), newX, newY, drawBox.x(), newY]);
        this.updateRectProperty(drawBox);
        break;
      }
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME: {
        const originalPoints = [...drawBox.points()];
        //** Enable dragging, scaling, and rotation for the polygon  */
        this.onDrawBoxDragResizeRotateHandler(e, drawBox, originalPoints);
        break;
      }
    }
    // #tooltip update toolTip LabelName:****************
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.updateToolTipLabelPositionBasedCurrentBox(drawBox);

  }

  onDrawBoxMouseOverHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    if (this.controlClicked === Annotation2DControl.POLYLINE) return;
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_MOVE;
  }

  onDrawBoxMouseOutHandler() {
    if (this.controlClicked === Annotation2DControl.POLYLINE) return;
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_DEFAULT;
  }
  // -----------------------------------------------------------------------------------
  // on Polygon vertices -points & drag : left click 
  async onDrawBoxMouseClickHandler(evt: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    if (evt.evt.button === 0) { // Left-click detected
      document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_CROSS;
      this.onDrawBoxLeftClickVertices(drawBox);
    }
  }

  // Create Vertices  -----------------------------------
  onDrawBoxLeftClickVertices(drawBox: Line) {
    const shapeName = drawBox.name();
    this.setNewRefPoint(drawBox);
    this.removeTransformer();
    this.removeActiveDragVertices(drawBox);
    switch (shapeName) {
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME:
        this.handleDrawBoxVertices(drawBox);
        break;
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME:
        this.handlePolyVertices(drawBox);
        break;
      default:
        break;
    }
  }

  setNewRefPoint(drawBox: Line) {
    if (!drawBox.getAttr('newRefPoint')) {
      drawBox.setAttr('newRefPoint', drawBox.points());
    }
  }

  removeTransformer() {
    if (this.transformer) {
      this.transformer.destroy();
      this.transformer = null;
    }
  }

  removeActiveDragVertices(drawBox: Line) {
    const id = drawBox.id();
    if (this.currentActiveVertice && `circle_${id}` !== this.currentActiveVertice) {
      this.removeCircleFromGroup(this.currentActiveVertice);
    }
  }

  handleDrawBoxVertices(drawBox: Line) {
    const isVerticed = this.selectedItem.isVerticed;
    if (isVerticed) {
      this.drawBox = drawBox;
      this.removeCircleFromGroup(`circle_${drawBox.id()}`);
      this.currentActiveVertice = '';
      return;
    }
    const scaleX = drawBox.scaleX();
    const scaleY = drawBox.scaleY();
    const newHeight = drawBox.height() * scaleY;
    const newWidth = drawBox.width() * scaleX;
    drawBox.height(newHeight);
    drawBox.width(newWidth);
    drawBox.scaleX(1);
    drawBox.scaleY(1);
    const geometricRectangle: ICanvasRectangleBox = {
      x: drawBox.x(),
      y: drawBox.y(),
      width: drawBox.width(),
      height: drawBox.height(),
      id: drawBox.id(),
    };
    const polygonPoints = this.annotationVisualCanvasService.convertRectangleToPolygonCoordinate(geometricRectangle);
    this.getPolygonDragVertices(polygonPoints, drawBox, ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME);
  }

  handlePolyVertices(drawBox: Line) {
    const coordinates = drawBox.getAttr('newRefPoint') || drawBox.points();
    this.getPolygonDragVertices(coordinates, drawBox, ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME);
  }
  // End ----------------------------------

  getPolygonDragVertices(coordinates: number[], drawBox: Line, shapeName: string) {
    this.polygonVerticePoints = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      const x: number = coordinates[i];
      const y: number = coordinates[i + 1];
      this.polygonVerticePoints.push({ "x": x, "y": y })
    }
    // Create circles at each vertex point
    this.polygonVerticePoints.forEach((point: any, index) => {
      this.createVerticesPoints(point, drawBox, index, 'vertexPoint'); // `Vertex_${i}`
    });
    this.layer.add(this.backgroundImgGroup);
    this.stage.add(this.layer);
    this.layer.batchDraw();
    this.selectedItem.isVerticed = true;
  }

  // create vertices by given data:
  createVerticesPoints(point: any, drawBox: Konva.Line, index: string | number, position: string) {
    const shapeName = drawBox.name();
    const drawBoxId = drawBox.id() || drawBox.getAttr('tempId');
    const isZoomed = this.stage.scaleX();
    const circle = new Konva.Circle({
      x: point.x,
      y: point.y,
      radius: (isZoomed >= 1) ? this.annotationVisualCanvasService.calculateCircleRadius(this.stage) : ANNOTATION_DRAWBOX_INIT_PROPERTY.RADIUS, //Add this radius: position === 'vertexPoint' ? ANNOTATION_DRAWBOX_INIT_PROPERTY.RADIUS : 20,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRCLE_PRIMARY,  //Add this fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRLCE_FILL_RED,
      draggable: true,
      id: `circle_${drawBoxId}_${index}`,
      name: `circle_${drawBoxId}`
    });
    this.backgroundImgGroup.add(circle);
    this.currentActiveVertice = circle.name();
    this.circleHandlerEvent(circle, drawBox, shapeName); // Add a dragend event handler to update the polygon points when a circle is dragged
  }

  // feature for mid vertice also
  getPolygonDragVerticesNew(coordinates: number[], drawBox: any, shapeName: string) {
    this.polygonVerticePoints = [];
    // Iterate through coordinates to create vertices and midpoints [Add new Vertices]
    for (let i = 0; i < coordinates.length; i += 2) {
      const x1 = coordinates[i];
      const y1 = coordinates[i + 1];
      const x2 = coordinates[(i + 2) % coordinates.length];
      const y2 = coordinates[(i + 3) % coordinates.length];
      // Add vertices to polygonVerticePoints // this.polygonVerticePoints.push({ "x": x1, "y": y1, "refName": "vertices"});
      this.createVerticesPoints({ "x": x1, "y": y1 }, drawBox, i, 'vertexPoint'); // `Vertex_${i}`
      // Calculate midpoint coordinates
      const midpointX = (x1 + x2) / 2;
      const midpointY = (y1 + y2) / 2;
      // Call createVerticesPoints midpoint  // this.polygonVerticePoints.push({ "x": midpointX, "y": midpointY, "refName": "midpoints"});
      this.createVerticesPoints({ "x": midpointX, "y": midpointY }, drawBox, (i + 1) % coordinates.length, 'midPoint'); // `MidPoint_${(i + 1) % coordinates.length}` 
    }
    this.selectedItem.isVerticed = true;
  }

  circleHandlerEvent(circle: Circle, drawBox: Line, shapeName: string) {
    circle.on('mouseover', (e) => {
      circle.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRLCE_FILL_RED); //bg fill //temper
      this.onDrawBoxMouseOverHandler(e, drawBox)
    });
    circle.on('mouseout', () => {
      circle.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRCLE_PRIMARY); //bg fill primary
      this.onDrawBoxMouseOutHandler()
    });
    circle.on('dragmove', () => this.circleDragMoveHandler(circle, drawBox, shapeName));
    circle.on('dragend', () => this.circleDragEndHandler(circle, drawBox));
  }

  circleDragMoveHandler(circle: Circle, drawBox: Line, shapeName: string) {
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.annotationVisualCanvasService.toolTipGroupShowHideFindByDrawBox(drawBox, false, this.backgroundImgGroup); //#tooltip current hide: 
    let pointIndex = Number(circle.id().split('_')[2]);
    // Update the corresponding polygon point          
    if (pointIndex !== -1) {
      this.polygonVerticePoints[pointIndex] = { x: circle.x(), y: circle.y() };
      //add mid point here > this.polygonVerticePoints[pointIndex] = { ...this.polygonVerticePoints[pointIndex] , x: circle.x(), y: circle.y(), i: pointIndex, refName: "vertices" }; // update newVertices midX
      this.updateLines(this.polygonVerticePoints, drawBox, shapeName); // Redraw the lines when a circle is moved
      circle.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRLCE_FILL_RED); //bg fill //temper
    }
  }

  updateLines(polygonPoints: any[], drawBox: Line, shapeName: string) {
    if (ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME === shapeName) this.annotationVisualCanvasService.removeLinesFromShapeGroup(drawBox.id(), this.backgroundImgGroup);
    if (ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME === shapeName) this.removeRectangleFromGroup(drawBox.id());
    //add mid point > const verticesRegroupOnly = polygonPoints.filter((elm)=> elm.refName === 'vertices'); //add newVertices Mid
    let polygonPoints1d = polygonPoints.map(elm => [elm.x, elm.y]).flat();
    this.createPolylineByPoints(polygonPoints1d, drawBox)
  }

  createPolylineByPoints(polygonPoints1d: any[], drawBox: Line) {
    const isZoomed = this.stage.scaleX()
    const line = new Konva.Line({
      points: polygonPoints1d,
      fill: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_FILL,
      stroke: this.controlActionClicked === this.ANNOTATION_2D_CONTROL_ACTION.VERTICE ? ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE_BLACK: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_STROKE,
      strokeWidth: (isZoomed >= 1) ? this.annotationVisualCanvasService.calculateStrokeWidth(this.stage) : ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      closed: true,
      hitStrokeWidth: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_POLY_STROKEWIDTH_VIEW,
      name: ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME,
      id: drawBox.id(), // Access the ID of the drawBox
      draggable: !this.thumbnailMode && this.isAnnotationEditPermission,
    });
    // shapeGroup: creation ---------
    let shapeGroup = new Konva.Group({
      draggable: false,
      name: 'shapeGroup', // Set a name for the group
      id: drawBox.id(),
    });
    shapeGroup.add(this.drawBox);
    this.backgroundImgGroup.add(shapeGroup);
    this.layer.add(this.backgroundImgGroup);
    this.stage.add(this.layer);
    this.layer.draw();
    this.drawBox = line;
    this.linePoints = polygonPoints1d;
    this.drawBoxEventHandler(this.drawBox);
  }

  // onDraw Vertices dragEnd to api call updated
  circleDragEndHandler(circle: Circle, drawBox: Line) {
    circle.fill(ANNOTATION_DRAWBOX_INIT_PROPERTY.CIRCLE_PRIMARY); //bg fill default primary
    this.undoAnnotationService.updateUndoHistory(drawBox);
    this.isUndo = true;
    this.updatePolygonProperty(this.drawBox, this.linePoints);
    drawBox.setAttr("newRefPoint", this.linePoints);
    // #tooltip update toolTip LabelName: (currently aiTag only)****************
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.updateToolTipLabelPositionBasedCurrentBox(drawBox);
    // -------------------------------------------------
    const circles = this.backgroundImgGroup.find('Circle');
    circles?.forEach((circle:Circle) => {
      circle.moveToTop();
    });
    this.resetDrawPolyLineProperties();
  }

  // Function to remove cicle from the background image group
  removeCircleFromGroup(drawBoxId: string) {
    let grpImageChild = this.backgroundImgGroup.find('.' + drawBoxId);
    if (grpImageChild && grpImageChild.length > 0) {
      grpImageChild.forEach((circle: Konva.Circle) => circle.remove())
      this.layer.draw();
    }
    this.selectedItem.isVerticed = false;
  }
  // Remove rectangle
  removeRectangleFromGroup(drawBoxId: string) {
    let grpImageChild = this.backgroundImgGroup.find('#' + drawBoxId);
    if (grpImageChild && grpImageChild.length > 0) {
      grpImageChild.forEach((rect: Konva.Circle) => rect.remove())
      this.layer.draw();
    }
    this.selectedItem.isVerticed = false;
  }
  // Remove active/deactive vertices remove
  removeActiveVertices(drawBox: Line) {
    if (this.currentActiveVertice) {
      if (`circle_${drawBox.id()}` !== this.currentActiveVertice) this.removeCircleFromGroup(this.currentActiveVertice)
    }
    if (this.selectedItem.isVerticed) this.removeCircleFromGroup(`circle_${drawBox.id()}`)
  }
  // Remove all vertices remove
  removeAllVerticeFromGroup() {
    if (this.backgroundImgGroup) {
      let grpImageChild = this.backgroundImgGroup.find('Circle');
      if (grpImageChild && grpImageChild.length > 0) {
        grpImageChild.forEach((circle: Konva.Circle) => circle.remove())
        this.layer.draw();
      }
      if (this.selectedItem.isVerticed) this.selectedItem.isVerticed = false;
    }
  }

  onRightClickTransform(drawBox: Konva.Line) {
    document.body.style.cursor = STAGE_2D_CANVAS_COMMON_VIEW.CURSOR_CROSS;
    this.removeActiveVertices(drawBox); // Remove previous active vertices
    const shapeName = drawBox.name();
    if (this.transformer) {
      this.transformer.destroy();
      this.transformer = null;
    }
    const transformer = this.annotationVisualCanvasService.getTransformerRotateValue(shapeName, drawBox, this.controlActionClicked, this.ANNOTATION_2D_CONTROL_ACTION.ROTATE, this.ANNOTATION_2D_CONTROL_ACTION.RESIZE)
    if (transformer) {
      this.layer.add(transformer);
      this.layer.draw();
      this.drawBox = drawBox;
      this.transformer = transformer;
    }
  }

  onDrawBoxTransformStartHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    this.removeActiveVertices(drawBox) //previous active vertices
    let shapeName = drawBox.name();
    switch (shapeName) {
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME:
        break;
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME:
        this.originalPoints = [...drawBox.points()];
        break;
    }
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.annotationVisualCanvasService.toolTipGroupShowHideFindByDrawBox(drawBox, false, this.backgroundImgGroup); //#tooltip current hide: 
  }

  onDrawBoxTransformHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line) {
    let shapeName = drawBox.name();
    switch (shapeName) {
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME:
        this.updateRectProperty(drawBox);
        break;
      case ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME:
        //** Enable dragging, scaling, and rotation for the polygon  */
        this.onDrawBoxDragResizeRotateHandler(e, drawBox, this.originalPoints);
        break;
      default:
        break;
    }
    // #tooltip update toolTip LabelName:****************
    if (drawBox.getAttr("aiTag") && drawBox.getAttr("componentName")) this.updateToolTipLabelPositionBasedCurrentBox(drawBox)
  }

  // Enable dragging, scaling, and rotation for the polygon
  onDrawBoxDragResizeRotateHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line, originalPoints: number[]) {
    this.linePoints = this.annotationVisualCanvasService.onDrawBoxDragResizeRotateHandlerServ(e, drawBox, originalPoints)
    this.undoAnnotationService.updateUndoHistory(drawBox);
    this.isUndo = true;
    this.updatePolygonProperty(drawBox, this.linePoints)
  }

  // Enable dragging, scaling for the polygon
  onDrawBoxDragResizeHandler(e: Konva.KonvaEventObject<MouseEvent>, drawBox: Line, originalPoints: number[]) {
    this.linePoints = this.annotationVisualCanvasService.onDrawBoxDragResizeHandlerServ(e, drawBox, originalPoints)
    this.undoAnnotationService.updateUndoHistory(drawBox);
    this.isUndo = true;
    this.updatePolygonProperty(drawBox, this.linePoints)
  }

  onDrawBoxKeyDownHandler(e: { key: string; keyCode?: number; }) {
    // Check if the pressed key is the delete key (keyCode 46) or the pressed key is the backspace key (keyCode 8) or 'Backspace'
    if (this.findingsService.editingTextFields) return;
    if ((e.keyCode === KEYBOARD_PROPERTY.KEY_CODE_DELETE || e.keyCode === KEYBOARD_PROPERTY.KEY_CODE_BACKSPACE || e.key === KEYBOARD_PROPERTY.KEY_NAME_BACKSPACE) && this.drawBox) {
      this.onSelectDrawBoxRemove();
    }
  }

  onDrawBoxDoubleClickHandler(drawBox: Rect | Konva.Line) {
    this.onSelectDrawBoxVerticeShape();
  }

  onConfirmToRemoveAnnotation(drawBox: Konva.Line) {
    this.removeActiveVertices(drawBox) //previous active vertices
    if (this.transformer) {
      this.transformer.destroy();
      this.transformer = null;
    }
    this.drawBox = drawBox;
    this.annotationBoxDeleteConfirmDialog.handleModalDisplay(true)
  }
  //Annotation delete confirmation
  confirmDeleteVisualAnnotateObject(confirm: boolean) {
    this.annotationBoxDeleteConfirmDialog.handleModalDisplay(confirm);
    if (confirm) {
      if (this.drawBox) {
        let annotateId = this.drawBox.id();
        this.undoAnnotationService.removeRecordsFromHistory(annotateId);
        this.removeAnnotationCanvasItem(this.drawBox, annotateId);
        this.drawBox.remove();
        this.clearLayer();
      }
      this.annotationBoxDeleteConfirmDialog.handleModalDisplay(false);
    }
    this.onResetDrawBoxWithClosedFindingPanel()
  }

  // Clear layer
  clearLayer() {
    this.controlClicked = Annotation2DControl.NO_ACTIVE_CONTROL;
    this.isExpanded = true;
    // Remove all shapes from the layer
    if (this.drawBox) this.drawBox.remove();
    this.currentActiveShapeId = '';
    this.currentActiveShapeName = '';
    this.isAddNewRecord = false;
  }

  // Canvas image viewer zoom InOut
  // Scroll ZoomInOut:
  canvasZoomInOut() {
    this.stage.on('wheel', (e: { evt: { preventDefault: () => void; deltaY: number } }) => {
      // Zoom canvas viewer
      this.annotationVisualCanvasService.handleWheelZoom(e, this.stage);
      this.calculateOverallZoom();
      let isZoomed = this.stage.scaleX();
      if (isZoomed >= 1) {
        this.drawBox?.strokeWidth(this.annotationVisualCanvasService.calculateStrokeWidth(this.stage));
        this.updateAllCircleRadii()
      }
    });
  }

  updateAllCircleRadii() {
    const circles = this.backgroundImgGroup.find('Circle');
    if(circles.length){
      const circleRadius = this.annotationVisualCanvasService.calculateCircleRadius(this.stage)
      circles.forEach((circle:Konva.Circle) => {
        circle.radius(circleRadius); // Update the radius of each circle
      });
      this.layer.batchDraw(); // Redraw the layer to reflect the changes
    }
}

  async toolTipLabelNameAdjustViewer(zoomPoint: number) {
    if (this.backgroundImgGroup) {
      let toolTipLabelGroups = this.annotationVisualCanvasService.findAllTooltipGroupBySelectedBackgroundGroupImage(this.backgroundImgGroup);
      toolTipLabelGroups.forEach(toolTipGrp => {
        const { Text: tooltipLabel } = this.annotationVisualCanvasService.getCurrentToolTipGroupChildern(toolTipGrp);
        tooltipLabel.fontSize(STAGE_2D_CANVAS_COMMON_VIEW.DEFAULT_TEXT_LABEL_FONT_SIZE / zoomPoint); //default fontSize:60
      });
    }
  }

  //onClick Undo
  onClickUndo(drawBox: Konva.Line){
    const polyPoints = this.undoAnnotationService.getPreviousPointsFromHistory(drawBox)
    this.isUndo = this.undoAnnotationService.updateUndoButtonStatus(drawBox.id())
    if(polyPoints){
      this.updatePolygonProperty(drawBox, polyPoints)
      this.annotationVisualCanvasService.setDefaultStrokeAllShapeInGroup(this.backgroundImgGroup)
      if (this.transformer) {
        this.transformer.destroy();
        this.transformer = null;
      }
      this.removeAllVerticeFromGroup();
      this.annotationVisualCanvasService.removeAllShapesFromShapeGroup(this.backgroundImgGroup);
      const imgProperties: ICanvasImageProperty = {
        image: this.imageObj,
        width: this.imageObj.width,
        height: this.imageObj.height,
        opacity: ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_OPACITY_INIT, // Start with opacity set to 0 for animated layer
        name: 'canvasImage',
      }
      const canvasInstance: IThumbnailInstance = {
        stage: this.stage,
        layer: this.layer,
        bgGroup: this.backgroundImgGroup,
      }
      if (!this.thumbnailMode) this.getAllDrawBoxes(canvasInstance, this.dataCooridnates, imgProperties);
    }
  }

  //onclick ZoomIn
  onClickZoomIn() {
    this.rangeValue += STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_LEVEL_FACTOR;
    this.emittedRangeValue(this.rangeValue.toString());
  }

  //onclick ZoomOut
  onClickZoomOut() {
    this.rangeValue -= STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_LEVEL_FACTOR;
    this.emittedRangeValue(this.rangeValue.toString());
  }

  // Function to handle zoom reset or zoom out
  zoomReset() {
    let zoomLevel = STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_LEVEL_INIT; // Reset zoom level to 1
    this.stage.scale({ x: zoomLevel, y: zoomLevel });
    this.stage.batchDraw();
  }

  emittedRangeValue(value: string) {
    let zoomValue = (parseInt(value) / 100) * 4;
    this.rangeValue = Number(value);
    this.backgroundImgGroup.scaleX(zoomValue);
    this.backgroundImgGroup.scaleY(zoomValue);
    this.layer.batchDraw();
  }

  // on zoom button click
  zoomOnClick(zoomType: 'in' | 'out') {
    const oldScale = this.stage.scaleX();
    let newScale = zoomType === 'in' ? oldScale * this.zoomScalevalue : oldScale / this.zoomScalevalue;
    newScale = Math.max(0, Math.min(newScale, STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_IN_MAX_LEVEL));
    if (newScale !== oldScale) {
      this.stage.scale({ x: newScale, y: newScale });
      this.stage.batchDraw();
      this.calculateOverallZoom();
    }
  }

  //Change the zoom level using the slider
  updateZoomLevel(){
    const zoomScale = Math.max(0.1, Math.min(this.zoomValue / 100, STAGE_2D_CANVAS_COMMON_VIEW.ZOOM_IN_MAX_LEVEL));
    this.stage.scale({ x: zoomScale, y: zoomScale });
    this.stage.batchDraw();
  }

  // reset zoom to fit window
  resetZoom() {
    if (this.backgroundImageData) {
      // set scale based on available space
      const widthScale = this.stage.width() / this.backgroundImageData.width();
      const heightScale = this.stage.height() / this.backgroundImageData.height();
      let fitScale = Math.min(widthScale, heightScale);
      fitScale = Math.max(0, Math.min(fitScale, 2));
      this.stage.scale({ x: fitScale, y: fitScale });
      // center scaled image
      this.backgroundImgGroup.position({ x: 0, y: 0 })
      const imageScaledWidth = this.backgroundImageData.width() * fitScale;
      const imageScaledHeight = this.backgroundImageData.height() * fitScale;
      const offsetWidth = this.stage.width() - imageScaledWidth;
      const offsetHeight = this.stage.height() - imageScaledHeight;
      this.stage.position({ x: offsetWidth / 2, y: offsetHeight / 2 });
      this.stage.batchDraw();
      this.calculateOverallZoom();
    }
  }
  // Canvas image viewer zoom InOut

  minMaxmizedCanvasViewer() {
    this.annotationVisualCanvasService.setExpandImageviewer(!this.isExpanded);
  }

  //UPDATE RECTANGLE TO POLYGON & PAYLOAD SETUP 
  // Get Rect box position property:
  updateRectProperty(rect: Rect | Konva.Line) {
    const { polygonPoints2d, geometricRectangle } = this.annotationVisualCanvasService.updateRectPropertyServ(rect, this.imageObj)
    this.getGeometryValueToPayload(polygonPoints2d, rect, geometricRectangle)
  }

  updatePolygonProperty(polygon: Line, linePoints: number[]) {
    const polygonPoints2d: [number, number][] = this.annotationVisualCanvasService.setConvertPolygonImageBasedXY([...linePoints], this.imageObj);
    let geometricPolygon: ICanvasRectangleBox = {
      points: polygon.points(),
      id: polygon.id(),
    };
    polygon.setAttr('newRefPoint', linePoints);
    this.getGeometryValueToPayload(polygonPoints2d, polygon, geometricPolygon);
  }

  // #tooltip updations current DrawBox:
  updateToolTipLabelPositionBasedCurrentBox(drawBox: Line<LineConfig> | Konva.Rect) {
    // get current active toolTip:
    let toolTipLabelGroup = this.annotationVisualCanvasService.currentActiveToolTipGroup(drawBox.id(), this.backgroundImgGroup);
    // hasChildren and update position:
    this.annotationVisualCanvasService.toolTipPlacedPositionTopOfShape(drawBox, toolTipLabelGroup);
    // show current the tooltip label:
    this.annotationVisualCanvasService.toolTipGroupShowHideFindByDrawBox(drawBox, true, this.backgroundImgGroup); //#tooltip show
  }

  // rectangle payload:
  getGeometryValueToPayload(polygonPoints: [number, number][], shape: Rect | Konva.Line, geometricRectangle: ICanvasRectangleBox) {
    this.currentActiveShapeId = shape.id(); // Access the ID of the rectangle or polygon
    this.currentActiveShapeName = shape.name();
    this.currentActiveShapeAiTag = shape.getAttr('aiTag');
    const payloadItem = this.geomtryPayloadData(polygonPoints)
    this.saveAnnotationCanvas2dObject(geometricRectangle, payloadItem);
  }

  geomtryPayloadData(polygonPoints: [number, number][]) {
    let inputTag: string[] = [];
    const currentAnnotatedRecord = this.selectedItem.annotationRecords.find((record: { _id: string; })=>record._id === this.currentActiveShapeId)
    if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_GRID) {
      inputTag = this.currentActiveShapeAiTag ? [STAGE_2D_CANVAS_COMMON_VIEW.AI_TAG] : [STAGE_2D_CANVAS_COMMON_VIEW.MANUAL_TAG];
    } else if (this.moduleType === SHARE_PROPERTIES.MODULE_TYPE_TWIN) {
      inputTag = currentAnnotatedRecord ? [...currentAnnotatedRecord.tags] : [STAGE_2D_CANVAS_COMMON_VIEW.MANUAL_TAG]
    }
    const geometricPolygon: IGeometricPolygon = {
      type: CANVAS_GEOMETRIC_TYPE.POLYGON_TYPE,
      coordinates: [polygonPoints]
    };
    const payloadPostItem: ISaveAnnotationItems = {
      type: this.annotation_payload_type,
      dataResourceId: this.selectedItem.dataResourceId,
      geometry: geometricPolygon,
      confidence: null,
      tags: inputTag,
    };
    return payloadPostItem;
  }

  //API INTEGERATIONS 
  saveAnnotationCanvas2dObject(rectCoordinate: ICanvasRectangleBox, payloadPostItem: ISaveAnnotationItems) {
    if (this.currentActiveShapeId) {
      let filteredAnnotateRecordIndex = this.selectedItem.annotationRecords.findIndex((elem: IGeometryAnnotationDto) => elem._id == this.currentActiveShapeId);
      if (filteredAnnotateRecordIndex > -1) {
        if (this.currentActiveShapeName === ANNOTATION_DRAWBOX_INIT_PROPERTY.DRAWBOX_NAME) this.selectedItem.annotationRecords[filteredAnnotateRecordIndex].geometry.coordinates = payloadPostItem.geometry?.coordinates;
        if (this.currentActiveShapeName === ANNOTATION_DRAWBOX_INIT_PROPERTY.POLY_NAME) this.selectedItem.annotationRecords[filteredAnnotateRecordIndex].geometry.coordinates = payloadPostItem.geometry?.coordinates; // 0 to 1 conversion
        this.isAddNewRecord = false;
        let respData = {
          item: this.selectedItem, method: STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_UPDATE,
          payload: payloadPostItem, keyId: this.currentActiveShapeId, itemType: this.currentActiveShapeName
        };
        this.onUpdatedCanvasStageItems.emit(respData);
      }
    } else {
      let respData = {
        item: this.selectedItem, method: STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_ADD,
        payload: payloadPostItem, keyId: "", rectCoordinate: rectCoordinate, itemType: this.currentActiveShapeName
      };
      this.onUpdatedCanvasStageItems.emit(respData);
    }
  }

  removeAnnotationCanvasItem(rectBox: Rect | Konva.Line, annotateId: string) {
    if (annotateId) {
      this.onDeleteCanvasAnnotation.emit({ item: this.selectedItem, method: STAGE_2D_CANVAS_COMMON_VIEW.ANNOTATION_PAYLOAD_REMOVE, rect: rectBox, keyId: annotateId });
    }
  }
}
