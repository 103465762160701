<div class="card">
  <div
    id="{{ item.accId }}"
    class="card-header accordion-collapse-wrap"
    [class.collapsed]="accordionIndex !== accordionShowIndex"
  >
    <a class="mb-0 d-flex">
      <button
        class="d-flex align-items-center accordion-title-wrap"
        (click)="showOrHideAccordion(accordionIndex)"
      >
        <img
          class="component-list-item-icon"
          (click)="
            handleComponentVisibility({
              event: $event,
              componentList: item,
              isList: true
            })
          "
          [src]="
            (item.visible ? '/icon/eye-opened.svg' : '/icon/eye-closed.svg')
              | assetUrl
          "
          alt="Hide Scene Object"
          width="14"
          height="14"
        />

        <img
          class="accordion-arrow"
          [src]="'/icon/arrow-right.svg' | assetUrl"
          alt="accordion-arrow"
        />
        <span class="accordion-name-wrap">
          <span class="label">{{ item.accLabel || "" }}</span>
          <span class="count"
            >{{ item.childrenPropertyCount || 0 }} items found</span
          >
        </span>
      </button>
    </a>
  </div>
  <div
    id="{{ item.accTargetName }}"
    [ngClass]="getAccordion()"
    [ngStyle]="getAccordionStyle()"
    [attr.aria-labelledby]="item.accId"
  >
    <div class="card-body">
      <ul>
        @for (
          innerItem of item.childrenProperties;
          track innerItem?.segmentId
        ) {
          <li
            class="d-flex align-items-center py-3 flex-column"
            [ngClass]="
              innerItem?.segmentId === selectedSegmentId ? 'active' : ''
            "
          >
            <button
              class="d-flex justify-content-between component-list-item-link"
              (click)="openAssociation(innerItem)"
            >
              <div class="d-flex align-items-center">
                <span
                  class="component-list-item-icon"
                  (click)="
                    handleComponentVisibility({
                      event: $event,
                      component: innerItem,
                      isList: false,
                      componentList: item
                    })
                  "
                >
                  <img
                    [src]="
                      (innerItem.visible
                        ? '/icon/eye-opened.svg'
                        : '/icon/eye-closed.svg'
                      ) | assetUrl
                    "
                    alt="Hide Scene Object"
                    width="14"
                    height="14"
                  />
                </span>
                @if (showdDisplayItem()) {
                  <button
                    class="arrow-button"
                    (click)="showOrHideProperties(innerItem.segmentId)"
                    [class.collapsed-form]="
                      showSelectedComponent !== innerItem.segmentId
                    "
                  >
                    <img
                      class="arrow-right"
                      [src]="'/icon/arrow-right.svg' | assetUrl"
                      alt="arrow-right"
                    />
                  </button>
                }
                <div class="name-wrapper">
                  <span>
                    @if (isEditId === innerItem.segmentId) {
                      <input
                        #inputElement
                        class="segment-input"
                        [(ngModel)]="currentName"
                        (blur)="onClickOutside(innerItem)"
                        (keydown.enter)="handleUpdateName(innerItem.segmentId)"
                        (keydown.escape)="onClickOutside(innerItem)"
                      />
                    } @else {
                      <span class="segment-name">
                        {{ innerItem?.name || "" }}
                      </span>
                    }
                  </span>
                  @if (innerItem?.src) {
                    <span>
                      <img
                        class="user-icon"
                        [src]="innerItem?.src"
                        alt="user-icon"
                      />
                    </span>
                  }
                  @if (shouldAIValue(innerItem)) {
                    <span class="acc-percentage ml-2"
                      >AI {{ innerItem?.value }}%</span
                    >
                  }
                </div>
              </div>
              <div class="d-flex flex-column gap-2">
                @if (showdDisplayItem()) {
                  <div class="d-flex gap-3 align-items-center">
                    <button
                      class="edit-button"
                      (click)="handleEditName($event, innerItem)"
                      [class.disabled]="
                        innerItem.properties?.component_reference
                      "
                    >
                      <img
                        [src]="'/icon/pencil-icon.svg' | assetUrl"
                        alt="edit-icon"
                        width="20"
                        height="20"
                      />
                    </button>
                    <button
                      type="button"
                      class="btn visual-button"
                      (click)="openImageGalary($event, innerItem)"
                    >
                      Visual
                    </button>
                  </div>
                }
                @if (showDisplayItem(innerItem)) {
                  <button
                    *permissionGuard="[
                      userRolePermissions.CANVAS_MEASUREMENT_DELETE
                    ]"
                    type="button"
                    class="btn"
                    (click)="
                      onDeleteMeasurementClick($event, innerItem.segmentId)
                    "
                  >
                    <img [src]="'/icon/delete.svg' | assetUrl" alt="trash" />
                  </button>
                }
                @if (showMeasurementDisplay(innerItem)) {
                  <div class="d-flex gap-3 justify-content-end pr-2">
                    <button
                      type="button"
                      class="icon-button"
                      [disabled]="!innerItem.hasReferencePoint"
                    >
                      <img
                        [src]="'/icon/ref-point.svg' | assetUrl"
                        alt="arrow-right"
                        width="15"
                        height="15"
                      />
                    </button>
                    <button
                      type="button"
                      class="icon-button"
                      [disabled]="!innerItem.hasReferenceVector"
                    >
                      <img
                        [src]="'/icon/direction-vector.svg' | assetUrl"
                        alt="arrow-right"
                        width="16"
                        height="15"
                      />
                    </button>
                  </div>
                }
              </div>
            </button>
            <div class="w-100">
              @if (showSelectedComponent === innerItem.segmentId) {
                <app-properties-form
                  [properties]="innerItem.properties!"
                  [componentId]="innerItem.segmentId"
                ></app-properties-form>
              }
            </div>
          </li>
        }
      </ul>
    </div>
  </div>
</div>
