<div class="component-container">
  <div class="component-container-layout">
    <div class="component-container-layout-header">
      <div class="d-flex justify-content-between">
        <span class="component-container-layout-header-title">
          AUDIT CHECKLIST
        </span>
        <button class="border-0 bg-transparent" (click)="closeModal()">
          <img [src]="'/icon/close-circle.svg' | assetUrl" alt="round" />
        </button>
      </div>
    </div>
    <div class="component-container-layout-content">
      <div class="text-center warning-container">
        @if (loadingInfo) {
          <div
            class="loader-wrapper d-flex justify-content-center align-items-center"
          >
            <div
              class="spinner-border spin-loader text-light"
              title="loading.."
            ></div>
          </div>
        } @else {
          @for (checkList of auditCheckList; track $index) {
            <div class="check-list-container">
              <p class="check-list-header">{{ checkList.title }}</p>
            </div>
            <table
              class="table table-borderless table-dark table-wrap audit-checklist-table"
            >
              <thead class="table-header">
                <tr>
                  <th class="table-header text-nowrap">Sr No</th>
                  <th class="table-header">Questions</th>
                  <th class="table-header text-nowrap">Labels</th>
                  <th class="table-header">Counts</th>
                </tr>
              </thead>
              <tbody>
                @for (
                  auditQuestion of checkList.auditQuestions;
                  track auditQuestion.title;
                  let i = $index
                ) {
                  <tr class="table-row">
                    <td class="table-col">{{ i + 1 }}</td>
                    <td class="table-col checklist-question">{{ auditQuestion.title }}</td>
                    <td class="table-col">
                      @for (
                        findingLabel of auditQuestion.findingLabels;
                        track $index
                      ) {
                        <p class="finding-label">
                          @if (findingLabel.componentLabel) {
                            {{ findingLabel.componentLabel.name + " - " }}
                          }
                          {{ findingLabel.name }}
                        </p>
                      }
                      @for (
                        measurementLabel of auditQuestion.measurementLabels;
                        track $index
                      ) {
                        <p class="finding-label">
                          {{ measurementLabel.name }}
                        </p>
                      }
                    </td>
                    <td class="table-col">
                      {{
                        auditQuestion.findingCount +
                          auditQuestion.measurementCount
                      }}
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          } @empty {
            <div
              class="loader-wrapper d-flex justify-content-center align-items-center text-light"
            >
              <p>Audit Checklist is empty</p>
            </div>
          }
        }
      </div>
    </div>
    @if (totalAuditCheckList.length) {
      <pagination
        [totalRecords]="totalAuditCheckList.length"
        [currentPage]="currentPage"
        [pageSize]="NUM_ITEMS"
        [maxVisiblePages]="windowSize"
        (pageChange)="pageChange($event)"
      ></pagination>
    }
  </div>
</div>
<a class="modal-backdrop" href="#" (click)="closeModal()"></a>
