import { IMAGE_TYPES } from '../constants';
import { IFindings } from './findings-listing.interface';

/* #region data resource interface */
export interface IDataResourceListResponse {
  records?: IDataResourceRecord[] | null;
  meta?: {
    nextCursor?: string;
    prevCursor?: any;
    [key: string]: any;
  };
  [key: string]: any;
}
export interface IGetAllImages {
  projectId: string;
  missionId: string;
  resourceType: typeof IMAGE_TYPES.IMAGE_RGB | typeof IMAGE_TYPES.IMAGE_THERMAL;
  tags: string[];
  pageLimit: number | string;
  nextCursor?: number | string;
  isPreSignedUrl?: boolean;
  resourceTag: string;
}
export interface IDataResourceRecord {
  _id: string;
  projectId: string;
  missionId: string;
  siteId: string;
  resourceType: string;
  tags?: string[] | null;
  parentResourceId?: null;
  metadata: {
    exif?: IDataResourceExif;
    [key: string]: any;
  };
  storage?: {
    storageType?: string;
    files?: IDataResourceFilesEntity[] | null;
    [key: string]: any;
  };
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  workspaceId: string;
  recordType: string;
  id?: string;
  [key: string]: any;
  preSignedUrl: string;
}
export interface IDataResourceExif {
  dateTimeOriginal?: string;
  cameraModel?: string;
  fieldOfViewDegree?: number;
  focalLengthMM?: number;
  focalLength35Efl: number;
  imageName: string;
  imageWidth: number;
  imageHeight: number;
  gpsLatitude: number;
  gpsLongitude: number;
  relativeAltitudeMeters: number;
  absoluteAltitudeMeters: number;
  flightPitchDegree: number;
  flightYawDegree: number;
  flightRollDegree?: null;
  gimbalPitchDegree: number;
  gimbalYawDegree: number;
  gimbalRollDegree: number;
  sensorWidthMM: number;
  sensorHeightMM: number;
  gpsMapDatum: string;
  gpsLatitudeRef: string;
  gpsLongitudeRef: string;
  imageType: string;
  emissivity?: number;
  objectDistance?: number;
  reflectedTemp?: number;
  relativeHumidity?: number;
  atmTemp?: number;
}
export interface IDataResourceFilesEntity {
  s3Key: string;
  s3Bucket: string;
  sizeBytes: number;
  extension: string;
  s3Etag: string;
  origUploadRelativePath: string;
  preSignedUrl: string;
  [key: string]: any;
}
/* #region data resource interface */
/* #region annotation canvas interface */
export interface IGeometryAnnotationListResponse {
  records: IGeometryAnnotationDto[];
  meta?: {
    nextCursor: string;
    prevCursor: any;
    [key: string]: any;
  };
  [key: string]: any;
}
export interface IGeometryAnnotationDto {
  projectId?: string;
  missionId: string;
  dataResourceId: string;
  geometry: {
    title?: string;
    type?: string;
    coordinates: any[];
  };
  confidence: number | null;
  classLabels: any[];
  collectionId: string;
  tags?: any[];
  metadata?: {};
  coorinate?: any[];
  stageProperty?: {
    id: string;
    width: number;
    height: number;
  };
  verify_checked?: boolean;
  verify_removed?: boolean;
  id?: number;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  findingId?: string | null;
  assetFindings?: IFindings | null;
  [key: string]: any;
}
export interface IDataResourceExif2 {
  imageName?: string;
  dateTimeOriginal?: string;
  cameraModel?: string;
  fieldOfViewDegree?: number;
  focalLengthMM?: number;
  focalLength35Efl?: number;
  imageWidth?: number;
  imageHeight?: number;
  gpsLatitude?: number;
  gpsLongitude?: number;
  relativeAltitudeMeters?: number;
  absoluteAltitudeMeters?: number;
  flightPitchDegree?: number;
  flightYawDegree?: number;
  flightRollDegree?: number;
  gimbalPitchDegree?: number;
  gimbalYawDegree?: number;
  gimbalRollDegree?: number;
  sensorWidthMM?: number;
  sensorHeightMM?: number;
  gpsMapDatum?: string;
  gpsLatitudeRef?: string;
  gpsLongitudeRef?: string;
  imageType?: string;
  emissivity?: number | string;
  objectDistance?: number | string;
  reflectedTemp?: number | string;
  relativeHumidity?: number | string;
  atmTemp?: number | string;
  irWindowTemp?: number;
  irWindowTransmission?: number;
  planckR1?: number;
  planckR2?: number;
  planckO?: number;
  planckB?: number;
  planckF?: number;
  atmTransAlpha1?: number;
  atmTransAlpha2?: number;
  atmTransBeta1?: number;
  atmTransBeta2?: number;
  atmTransBetaX?: number;
}
