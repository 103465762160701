import { IGeometryAnnotationDto } from './annotation-api-response.interface';

export interface IFindingsListing {
  _id: string;
  name?: string;
  label?: string;
  description?: string;
  projectId?: string;
  siteId?: string;
  assetId?: string;
  componentId?: string;
  workspaceId?: string;
  missionId: string;
  tags?: string[];
  pageLimit?: string | number;
  findingLabel: IFindingLabel;
  severityLabel?: ISeverityLabel;
  annotations: string[];
  attachments: string[];
  assetName?: string;
  geometry: {
    type: string;
    coordinates: any;
  };
  annotationId?: string;
}

export interface IInspectionFindingsResponse {
  meta: IFindingMeta;
  records: IFindingsListing[];
}

export interface IFindingMeta {
  nextCursor: string | null;
  prevCursor: string | null;
}

export interface IFindingsAnnotations {
  classLabels: string[];
  collectionId: string;
  confidence: string | null;
  createdAt: string;
  createdBy: string;
  dataResourceId: string;
  missionId: string;
  projectId: string;
  tags: string[];
  updatedAt: string;
  updatedBy: string;
  workspaceId: string;
  __v: number;
  _id: string;
}

export interface IFindingLabel {
  componentLabel: string;
  name: string;
  _id: string;
}

export interface ISeverityLabel {
  name: string;
  _id: string;
}

export interface IFindings {
  _id: string;
  label: string;
  assetId: string;
  missionId: string;
  componentId: string;
  workspaceId: string;
  findingType: string;
  severityLabel: {
    _id: string;
    name: string;
  };
  annotations:
    | {
        _id: string | undefined;
        dataResourceId: string;
      }[]
    | IGeometryAnnotationDto[];
  tags: string[];
  findingLabel: {
    _id: string;
    name: string;
    componentLabel: {
      _id: string;
      name: string;
    };
  };
  attachments?: string[];
}
export interface IMeasurementDetail {
  measurementName: string;
  measurementValue: string;
  measurementType?: string;
}
export interface IDeltaCalcProperty {
  shownDelta: boolean;
  deltaValue: number;
  temperatureType: string;
  highSpotMeasureName: string;
  lowerestSpotMeasureName: string;
}
export interface IThermalParameters {
  name: string;
  codeName: string;
  value: string | number;
  type: string;
}

export interface IFindingsFilter extends IFindingsListing {
  loading?: boolean;
  checked?: boolean;
  metadata?: {};
  annotationId?: string;
  attachments: string[];
}

export interface ISaveAnnotation {
  name?: string;
  assetId?: string;
  findingLabel: IFindingLabel;
  description?: string;
  missionId: string;
  componentId?: string;
  workspaceId?: string;
  status?: string;
  annotations: string[];
  attachments?: string[];
  geometry?: any;
}
export interface IFindConfirmProperty {
  title: string;
  message: string;
  confirmBtnText: string;
  cancelBtnText: string;
}
export interface IFindRemoveData {
  findingId: string;
  annotationId: string;
}
export interface IFindingsdata extends IFindingsListing {
  annotations: any[];
  resourceType?: string[];
}
