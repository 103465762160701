import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from './routes';
import {
  IAuditChecklist,
  IAuditChecklistApiResponse,
} from '../interfaces/audit-checklist.interface';
import { catchError, expand, of, reduce } from 'rxjs';
import { NotificationService } from './notification.service';
import { EventTypes } from '../interfaces';
import { API_MESSAGE_EVENTS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AuditChecklistService {
  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
  ) {}

  getAllAuditCheckList(missionId?: string) {
    let httpParams = new HttpParams().set('pageLimit', 50);
    if (missionId) {
      httpParams = httpParams.set('missionId', missionId);
    }
    return this.getAuditCheckList(httpParams).pipe(
      expand((response) =>
        response.meta?.nextCursor
          ? this.getAuditCheckList(httpParams, response.meta.nextCursor).pipe(
              catchError((err) => {
                const errorMessage = err?.error?.message;
                this.notificationService.showToast({
                  type: EventTypes.error,
                  message:
                    Array.isArray(errorMessage) && errorMessage?.length
                      ? errorMessage[0]
                      : API_MESSAGE_EVENTS.GENERIC_ERR_MESSAGE,
                });
                return of({
                  meta: { prevCursor: null, nextCursor: null },
                  records: [],
                });
              }),
            )
          : of(),
      ),
      reduce(
        (acc: IAuditChecklist[], reponse) => acc.concat(reponse.records),
        [],
      ),
    );
  }

  getAuditCheckList(httpParams: HttpParams, nextCursor?: string) {
    if (nextCursor) {
      httpParams = httpParams.set('nextCursor', nextCursor);
    }
    return this.httpClient.get<IAuditChecklistApiResponse>(
      Routes.GET_AUDIT_CHECKLIST_SUMMARY,
      {
        params: httpParams,
      },
    );
  }
}
