import { Component, Input } from '@angular/core';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-draw-tools',
  templateUrl: './draw-tools.component.html',
  styleUrl: './draw-tools.component.scss',
})
export class DrawToolsComponent {
  @Input() mapBoxGl!: mapboxgl.Map;
  private draw!: MapboxDraw;
  showFormFinding = false;
  compDraw = [];
  rectangleGeoSubject = new BehaviorSubject<any>(null); // Initialize BehaviorSubject
  squareLength = 50;
  topLeftSquare = -135;
  topRightAngle = -45;

  botRight = 45;
  botleft = 135;
  geoJSONPoint: {
    title: string;
    type: string;
    coordinates: [number, number];
    bbox?: number[];
  };
  squareFeatureId: string;
  clickListener: (e: mapboxgl.MapMouseEvent) => void;

  ngOnInit(): void {
    this.initializeDrawTools();
  }

  ngOnChanges(): void {
    this.initializeDrawTools();
  }

  ngOnDestroy(): void {
    if (this.mapBoxGl && this.draw) {
      this.mapBoxGl.removeControl(this.draw);
    }
  }

  initializeDrawTools(): void {
    if (this.mapBoxGl && !this.draw) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
        defaultMode: 'draw_polygon',
      });
    }
  }

  drawPolygonFindings(): Observable<any> {
    if (!this.mapBoxGl.hasControl(this.draw)) {
      this.mapBoxGl.addControl(this.draw);
    }

    const polygonFeature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[]],
      },
    };

    const polygonGeoSubject = new BehaviorSubject<any>(null); // Initialize BehaviorSubject

    const mapClickListener = (e: any) => {};

    this.mapBoxGl.on('click', mapClickListener);

    this.mapBoxGl.fire('draw.create', { features: [polygonFeature] });

    this.mapBoxGl.on('draw.create', (e: any) => {
      this.mapBoxGl.getCanvas().style.cursor = 'crosshair';

      const feature = e.features[0];
      const polygonGeo = feature.geometry.coordinates;

      this.mapBoxGl.addLayer({
        id: 'area',
        type: 'line',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'line-color': '#00F0FF',
          'line-opacity': 1,
        },
      });

      // Emit the geoJSONPoint after the polygon is created
      const geoJSONPoint = {
        title: 'GeoJSON Polygon',
        type: 'Polygon',
        coordinates: polygonGeo,
      };
      polygonGeoSubject.next(geoJSONPoint); // Emit value
    });

    this.mapBoxGl.on('draw.update', (e: any) => {
      const feature = e.features[0];
      const layerId = 'area';

      if (this.mapBoxGl.getLayer(layerId)) {
        this.mapBoxGl.removeLayer(layerId);
      }

      this.mapBoxGl.addLayer({
        id: layerId,
        type: 'line',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'line-color': '#00F0FF',
          'line-opacity': 1,
        },
      });
    });

    this.mapBoxGl.on('draw.delete', () => {});

    return polygonGeoSubject.asObservable(); // Return as observable
  }

  drawRectangle() {
    if (!this.mapBoxGl.hasControl(this.draw)) {
      this.mapBoxGl.addControl(this.draw);
    }

    this.mapBoxGl.off(
      'click',
      this.clickListener as (e: mapboxgl.MapMouseEvent) => void
    );

    this.clickListener = (e: mapboxgl.MapMouseEvent) => {
      const center = [e.lngLat.lng, e.lngLat.lat] as [number, number];
      const sideLength = this.squareLength; // 500 meters

      const square = this.createSquare(center, sideLength);

      this.draw.deleteAll();
      const addedFeature = this.draw.add(square);
      this.squareFeatureId = addedFeature[0];

      this.draw.changeMode('direct_select', {
        featureId: this.squareFeatureId,
      });

      this.mapBoxGl.off(
        'click',
        this.clickListener as (e: mapboxgl.MapMouseEvent) => null
      );

      const rectangleGeo = square.geometry.coordinates;
      const geoJSONPoint = {
        title: 'GeoJSON Polygon',
        type: 'Polygon',
        coordinates: rectangleGeo,
      };
      this.rectangleGeoSubject.next(geoJSONPoint);
    };

    this.mapBoxGl.on('click', this.clickListener);
    this.mapBoxGl.getCanvas().style.cursor = 'crosshair';

    this.mapBoxGl.on('draw.create', this.updateRectangleLayer.bind(this));
    this.mapBoxGl.on('draw.update', this.updateRectangleLayer.bind(this));

    this.mapBoxGl.on('dblclick', (e: mapboxgl.MapMouseEvent) => {
      if (this.squareFeatureId) {
        this.draw.changeMode('direct_select', {
          featureId: this.squareFeatureId,
        });
      }
    });
    const mapContainer = this.mapBoxGl.getContainer();
    mapContainer.classList.add('crosshair-cursor');
    return this.rectangleGeoSubject.asObservable(); // Return the observable
  }

  createSquare(center: [number, number], sideLength: number) {
    const halfSide = sideLength / 2;
    const options = { units: 'meters' as turf.Units };

    const topLeft = turf.destination(
      center,
      halfSide,
      this.topLeftSquare,
      options
    );
    const topRight = turf.destination(
      center,
      halfSide,
      this.topRightAngle,
      options
    );
    const bottomRight = turf.destination(
      center,
      halfSide,
      this.botRight,
      options
    );
    const bottomLeft = turf.destination(
      center,
      halfSide,
      this.botleft,
      options
    );

    const square = turf.polygon([
      [
        topLeft.geometry.coordinates,
        topRight.geometry.coordinates,
        bottomRight.geometry.coordinates,
        bottomLeft.geometry.coordinates,
        topLeft.geometry.coordinates,
      ],
    ]);

    return square;
  }

  updateRectangleLayer(e: any) {
    const feature = e.features[0];
    const layerId = 'rectangle';

    if (this.mapBoxGl.getLayer(layerId)) {
      this.mapBoxGl.removeLayer(layerId);
      this.mapBoxGl.removeSource(layerId);
    }

    this.mapBoxGl.addLayer({
      id: layerId,
      type: 'line',
      source: {
        type: 'geojson',
        data: feature,
      },
      paint: {
        'line-color': '#00F0FF',
        'line-opacity': 1,
        'line-width': 4,
      },
    });
  }

  removeAllDrawings(): void {
    if (this.mapBoxGl.getLayer('rectangle')) {
      this.mapBoxGl.removeLayer('rectangle');
    }
    if (this.mapBoxGl.getLayer('area')) {
      this.mapBoxGl.removeLayer('area');
    }
    if (this.mapBoxGl.getSource('rectangle')) {
      this.mapBoxGl.removeSource('rectangle');
    }
    if (this.mapBoxGl.getSource('area')) {
      this.mapBoxGl.removeSource('area');
    }
    this.mapBoxGl.on('draw.delete', () => {
      setTimeout(() => {
        this.draw.deleteAll();
      }, 0);
    });

    const hasDrawControl = this.mapBoxGl.hasControl(this.draw);
    if (hasDrawControl) {
      this.mapBoxGl.removeControl(this.draw);
    }
  }
}
