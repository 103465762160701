import { List } from 'lodash';

export const calculateMinMaxInList = <T extends Record<string, any>>(
  measurementDataList: Array<T>,
  key: keyof T,
) => {
  const measurementValues = measurementDataList.map((elm: T) =>
    parseFloat(elm[key]),
  );
  const maxVal: number = Math.max(...measurementValues);
  const minVal: number = Math.min(...measurementValues);
  return { maxVal, minVal };
};
