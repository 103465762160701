import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TABLE_DATA } from '../../../constants';
import { IAuditChecklist } from '../../../interfaces/audit-checklist.interface';
import { AuditChecklistService } from '../../../services/audit-checklist.service';

@Component({
  selector: 'app-audit-checklist',
  templateUrl: './audit-checklist.component.html',
  styleUrl: './audit-checklist.component.scss',
})
export class AuditChecklistComponent implements OnInit {
  @Input() missionId: string;
  @Output() closeAuditCheckList = new EventEmitter();

  constructor(private auditChecklistService: AuditChecklistService) {}

  loadingInfo = false;
  totalAuditCheckList: IAuditChecklist[] = [];
  auditCheckList: IAuditChecklist[] = [];
  currentPage: number = 1;
  NUM_ITEMS = 5;
  windowSize = TABLE_DATA.WINDOW_SIZE;

  ngOnInit(): void {
    this.filterCheckList();
    this.loadingInfo = true;
    this.auditChecklistService.getAllAuditCheckList(this.missionId).subscribe({
      next: (res) => {
        this.totalAuditCheckList = res;
        this.filterCheckList();
        this.loadingInfo = false;
      },
      error: () => {
        this.loadingInfo = false;
      },
    });
  }

  closeModal() {
    this.closeAuditCheckList.emit();
  }

  pageChange(page: number) {
    this.currentPage = page;
    this.filterCheckList();
  }

  filterCheckList() {
    const filterIndex = (this.currentPage - 1) * this.NUM_ITEMS;
    this.auditCheckList = this.totalAuditCheckList.slice(
      filterIndex,
      this.currentPage * this.NUM_ITEMS,
    );
  }
}
