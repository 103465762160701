import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { USER_ROLE_PERMISSIONS } from 'projects/digital-twin/src/app/constants';
import { API_MESSAGE_EVENTS, FORM_GROUP_CONSTANTS } from 'projects/digital-twin/src/app/constants/canvas.const';
import { ISceneDefinitionDocumentSingleVersionResponse } from 'projects/digital-twin/src/app/interfaces/api-response.interface';
import { EventTypes } from 'projects/digital-twin/src/app/interfaces/notification.interface';
import { IUserRolesAndPermissions } from 'projects/digital-twin/src/app/interfaces/permission.interface';
import { CanvasDataService } from 'projects/digital-twin/src/app/services/canvas-data.service';
import { NotificationService } from 'projects/digital-twin/src/app/services/notification.service';
import { SceneDefinitionDocumentService } from 'projects/digital-twin/src/app/services/scene-definition-document.service';
import { CustomValidatorModule } from 'projects/digital-twin/src/app/shared/custom-validator/custom-validator.module';
import { VERSION_REQUIRED } from '../../../constants';
import { LocalStorageService } from 'projects/digital-twin/src/app/services';

@Component({
  selector: 'app-version-info-save',
  templateUrl: './version-info-save.component.html',
  styleUrls: ['./version-info-save.component.scss']
})
export class VersionInfoSaveComponent {
  /* #region variable initialization/declaration */
  existingVersionName: string = "";
  currentSceneDefinitionDoc!: ISceneDefinitionDocumentSingleVersionResponse;
  userRolePermissions: IUserRolesAndPermissions = USER_ROLE_PERMISSIONS;
  requiredText = VERSION_REQUIRED;
  workspaceId: string = "";
  /* #endregion variable initialization/declaration */

  /* #region input/ouput/viewChild decorators */
  @Output() closeVersionSave = new EventEmitter();
  @Output() triggerSceneDefinitionDocEmitter = new EventEmitter();
  private unsubscribeSceneDef: Subject<void> = new Subject<void>();
  /* #endregion input/ouput/viewChild decorators */

  /* #region form group initialization with its controls */
  versionInfoFormGroup: FormGroup;

  initializeFormGroup() {
    this.versionInfoFormGroup = this.formBuilder.group({
      versionName: ['', [Validators.required]],
    });
  }

  get versionInfoFormGroupControls() {
    return this.versionInfoFormGroup.controls;
  }
  /* #endregion form group initialization with its controls */

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private sceneDefinitionDocumentService: SceneDefinitionDocumentService,
    private canvasDataService: CanvasDataService,
    private localStorageService: LocalStorageService
  ) {
    this.initializeFormGroup();
    const userData = this.localStorageService.getItem('user-data') ?? "";
    this.workspaceId = JSON.parse(userData)?.activeWorkspaceId || "";
    this.canvasDataService.sceneDefinitionData$.pipe(takeUntil(this.unsubscribeSceneDef)).subscribe({
      next: (sceneDoc) => {
        this.currentSceneDefinitionDoc = JSON.parse(JSON.stringify(sceneDoc));
        this.existingVersionName = sceneDoc?.name || "";
      },
      error: (err: any) => {
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeSceneDef.next();
    this.unsubscribeSceneDef.complete();
  }

  onCloseVersion() {
    this.closeVersionSave.emit(true);
  }

  onSubmit() {
    if (this.versionInfoFormGroup.valid) {
      this.currentSceneDefinitionDoc['name'] = this.versionInfoFormGroupControls['versionName'].value;
      this.currentSceneDefinitionDoc['workspaceId'] = this.workspaceId;
      this.saveSceneDefinitionDocument();
    } else {
      CustomValidatorModule.prototype.validateAllFields(this.versionInfoFormGroup);
      this.notificationService.showToast({ type: EventTypes.success, message: FORM_GROUP_CONSTANTS.REQUIRED_FIELD_MESSAGE });
    }
  }

  private saveSceneDefinitionDocument = () => {
    return this.sceneDefinitionDocumentService.saveSceneDefinitionDocument(this.currentSceneDefinitionDoc).subscribe({
      next: (response) => {
        if (response?._id) {
          this.notificationService.showToast({ type: EventTypes.success, message: response?.['message'] || `Scene definition document ${(API_MESSAGE_EVENTS.GENERIC_CREATE_SUCCESS_MESSAGE).toLowerCase()}` });
          this.triggerSceneDefinitionDocEmitter.emit(true);
          this.versionInfoFormGroup.reset();
          this.onCloseVersion();
        }
      }, error: (error) => {
        console.error(error);
        let errorObject: any = error;
        this.notificationService.showToast({ type: EventTypes.error, message: errorObject?.error?.message || API_MESSAGE_EVENTS.GENERIC_ERROR_MESSAGE });
      }
    });
  }
}
