@if (displayModal) {
<div class="component-container">
  <div class="component-container-layout">
    <div class="d-flex title-cont justify-content-between">
      <span class="component-container-layout-header-title"> FINDING </span>
      <button
        class="component-container-layout-header-close"
        (click)="closeModal()"
      >
        <img [src]="'/icon/close.svg' | assetUrl" alt="round" />
    </button>
    </div>
    <app-image-canvas-viewer
      [canvasQueryParamsObject]="canvasQueryParamsObject"
      [imageRGB]="collectImages"
      [annotationList]="primaryStoredAnnotationList"
      [isLoadCompletionStatus]="isLoadedDataImageStatus && isLoadedAnnotation"
      [moduleType]="currentModuleType"
      [isOrthoMapPreviewMode]="true"
      [setThumbnailViewerMode]="false"
    ></app-image-canvas-viewer>
  </div>
</div>
<div class="modal-backdrop" (click)="closeModal()"></div>
}
